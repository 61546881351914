import { PandaAvatar } from 'src/images';
import styles from './render-chat-messages.module.scss';
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { RenderQuestion } from './render-question';
import { RenderAnswer } from './render-answer';
import { Loader } from './chat-loader';
import { useSelector } from 'react-redux';
import { getUser } from 'shared/store/selectors/user.selector';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { faSquareChevronLeft, faSquareChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { formatFullName } from 'shared/utils';
import { AUTH_USER_TYPE_NORMAL } from 'shared/constants';
import { Avatar } from 'src/components/avatar/avatar.component';

const C8_AI = 'C8 Panda';

function toHistoryArray(message) {
  const result = [message];
  message.question.history?.forEach((question, index) => {
    result.push({
      question,
      answer: message.answer.history[index],
    });
  });
  return result.reverse();
}

function RenderQuestionAnswer({ message, renderAvatar, index, ref }) {
  const { lang, isMessageLoading } = useChatConversation();
  const historyArray = useMemo(() => toHistoryArray(message), [message]);
  const [historyIndex, setHistoryIndex] = useState(historyArray.length - 1); // start with the last element

  useEffect(() => {
    setHistoryIndex(historyArray.length - 1);
  }, [historyArray]);

  const toggle = leftOrRight => {
    setHistoryIndex(prev => prev + leftOrRight);
  };

  const renderHistoryToggle = useCallback(() => {
    if (historyArray.length <= 1) return null;

    return (
      <div className={styles.history}>
        <button disabled={historyIndex === 0} onClick={() => toggle(-1)} type="button">
          <FontAwesomeIcon icon={faSquareChevronLeft} />
        </button>
        <p>{`${historyIndex + 1}/${historyArray.length}`}</p>
        <button
          disabled={historyIndex === historyArray.length - 1}
          onClick={() => toggle(1)}
          type="button"
        >
          <FontAwesomeIcon icon={faSquareChevronRight} />
        </button>
      </div>
    );
  }, [historyArray.length, historyIndex]);

  return (
    <>
      <RenderQuestion
        message={historyArray[historyIndex].question}
        renderAvatar={renderAvatar}
        renderHistoryToggle={renderHistoryToggle}
        mainQuestionId={message.question.id}
      />

      {isMessageLoading(index) ? (
        <div className={styles.message}>
          {renderAvatar(false)}

          <div className={styles.content}>
            <h4>{lang.BOT_NAME}</h4>
            <Loader />
          </div>
        </div>
      ) : (
        <RenderAnswer
          message={historyArray[historyIndex].answer}
          renderAvatar={renderAvatar}
          ref={ref}
        />
      )}
    </>
  );
}

export function RenderChatMessages() {
  const lastElementRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const userProfile = useSelector(state => getUser(state));
  const { lang, clearChat, messages } = useChatConversation();

  const shortName = useMemo(
    () =>
      userProfile?.firstName?.charAt(0)?.toUpperCase() +
      userProfile?.lastName?.charAt(0)?.toUpperCase(),
    [userProfile?.firstName, userProfile?.lastName],
  );

  const renderAvatar = useCallback(
    isUser => {
      if (isUser) {
        return (
          <div className={styles.avatarWrapper}>
            <Avatar alt={shortName} />
          </div>
        );
      }

      return <Avatar img={PandaAvatar} alt={C8_AI} />;
    },
    [shortName],
  );

  const renderWelcomeMessage = useCallback(() => {
    return (
      <div className={styles.message}>
        {renderAvatar(false)}
        <div className={styles.content}>
          <h4>{C8_AI}</h4>
          <p>
            {lang.WELCOME_MESSAGE.replace(
              '{fullName}',
              userProfile.type === AUTH_USER_TYPE_NORMAL
                ? formatFullName(userProfile)
                : lang.HI_THERE,
            )}
          </p>
        </div>
      </div>
    );
  }, [lang.WELCOME_MESSAGE, renderAvatar, userProfile, lang.HI_THERE]);

  const scrollToBottom = () => {
    scrollContainerRef.current.scrollTo({
      top: scrollContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className={classNames(styles.root, 'comprehensive-text')} ref={scrollContainerRef}>
      {messages.length > 0 && (
        <button type="button" className={styles.clearChat} onClick={clearChat}>
          {lang.CLEAR_CHAT}
        </button>
      )}
      {renderWelcomeMessage()}

      {messages.map((message, index) => {
        return (
          <RenderQuestionAnswer
            key={index}
            message={message}
            renderAvatar={renderAvatar}
            index={index}
            ref={index === messages.length - 1 ? lastElementRef : null}
          />
        );
      })}
    </div>
  );
}
