import styles from './schedule-url-editor.module.scss';
import { useFormik } from 'formik';
import { Button, Divider, Select, TextInput } from 'src/components/design-system';
import { WORKSPACE_SCHEMA_SCHEDULE_URLS } from '../workspace-editor/workspace.schema';
import { MODES } from '../workspace-general-info';
import { useProfileOptionsField } from 'shared/hooks/profile.hooks';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useState } from 'react';

/**
 * ScheduleUrl editor component
 * @param {object} props
 * @param {import('../../types').ScheduleUrl} props.scheduleUrl the schedule url to edit
 * @param {'create' | 'edit'} props.mode the mode of the editor
 * @param {(values: import('../../types').ScheduleUrl) => Promise<void>} props.onSubmit function to submit the schedule url
 * @param {() => void} props.onCancel function to cancel the editor
 */
export function ScheduleUrlEditor({ scheduleUrl, mode, onSubmit, onCancel }) {
  const lang = useSelector(getLang('SETTINGS'));
  const [loading, setLoading] = useState(false);

  const applyChanges = async (values, { resetForm }) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    resetForm();
  };

  const { values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm } = useFormik({
    initialValues: !scheduleUrl
      ? {
          roles: [],
          professions: [],
          url: '',
          title: '',
        }
      : { ...scheduleUrl },
    validationSchema: WORKSPACE_SCHEMA_SCHEDULE_URLS,
    enableReinitialize: true,
    onSubmit: applyChanges,
  });

  const { professionOptions, roleOptions } = useProfileOptionsField();

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <TextInput
          label={lang.QGENDA_TITLE_LABEL}
          name="title"
          value={values.title}
          error={errors.title}
          onChange={e => setFieldValue('title', e.target.value)}
        />

        <TextInput
          label={lang.QGENDA_URL}
          name="url"
          value={values.url}
          error={errors.url}
          onChange={e => setFieldValue('url', e.target.value)}
        />

        <Select
          label={lang.PROFESSIONS}
          name="professions"
          value={values.professions}
          onChange={setFieldValue}
          size="large"
          options={professionOptions}
          type="tags"
          multiple
        />

        <Select
          label={lang.ROLES}
          name="roles"
          value={values.roles}
          onChange={setFieldValue}
          size="large"
          options={roleOptions}
          type="tags"
          multiple
        />
      </div>

      <Divider />

      <div className={styles.actions}>
        <Button type="default" onClick={handleCancel}>
          {lang.CANCEL}
        </Button>
        <Button loading={loading} type="primary" htmlType="submit" disabled={!isValid || !dirty}>
          {mode === MODES.CREATE ? lang.ADD_LINK : lang.APPLY_CHANGES}
        </Button>
      </div>
    </form>
  );
}
