import { connect } from 'react-redux';

import { ProfileResourceManager } from './profile-resource-manager.component';
import {
  addResourceToUser,
  removeResourceFromUser,
} from 'shared/store/actions/users-management.actions';

const mapStateToProps = ({ lang }) => ({
  lang: lang.CONTENT_SELECTOR,
});

const mapDispatchToProps = {
  addResourceToUser,
  removeResourceFromUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileResourceManager);
