import React from 'react';
import styles from './knowledge-binder.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { Cross, Search } from '../../../images';
import {
  SEARCH_TYPES_KEYS,
  Lang,
  KNOWLEDGE_TYPES,
  ROUTES,
  INTERNAL_ROUTING,
  CATEGORY_TYPES,
  DATA_KEYS,
} from 'shared/constants';
import { useExpiryFilterWithAdmin } from 'shared/hooks/knowledge.hooks';
import { Button, Loader, Tooltip } from '../../../components/core';
import { KnowledgeDeletePopup } from '../knowledge/components/knowledge-row/knowledgeDelete';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { SearchType } from '../../../components/searchType';
import { KnowledgeMovePopup } from '../knowledge/components/knowledge-row/knowledgeMove';
import { normalizedContains } from 'shared/utils/Utils';
import { TagsIcons } from '../../../components/tags/tagsIcons';
import { KnowledgeWrapperComponent } from '../knowledge/components/knowledge-row';
import {
  KnowledgeLinkEditor,
  KnowledgeLinkDeletePopup,
  KnowledgeLinkUpdatePopup,
  KnowledgeLinkMovePopup,
} from '../knowledge/components/knowledge-link-editor';
import { TextTag } from '../../../components/tags/text-tags';
import {
  KnowledgeAddPopupComponent,
  KnowledgeContentComponent,
  KnowledgeUploadFileComponent,
} from '../../../components/knowledge-content';
import { KnowledgeUploadExternalLinkComponent } from '../../../components/knowledge-content/knowledge-upload-external-link.component';
import { useLocation } from 'react-router-dom';
import { ShareBoxComponent } from '../../../components/share';
import { notification } from 'antd';
import { getExternalSyncIcon } from '../../../constants/external-sync-icons-mapper';

const KnowledgeBinderComponent = React.memo(function KnowledgeBinderComponent({
  id,
  title,
  subtitle,
  options,
  showExternalSync = false,
  knowledgeItems,
  knowledgeSelectItem,
  initialOpenState,
  adminMode,
  updateKnowledgeItemsOrder,
  ownerIds,
  tags,
  labels,
  lang,
  langTypes,
  langTooltip,
  routingParam = INTERNAL_ROUTING.QUERY_PARAMS.KNOWLEDGE,
  sharePath = null,
  loading = false,
  itemConfig = {},
}) {
  const { search } = useLocation();
  const [isOpen, setOpenState] = React.useState(initialOpenState);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchType, setSearchType] = React.useState(null);
  const [data, setData] = React.useState(knowledgeItems);

  const [deleteKnowledgeItem, setDeleteKnowledgeItem] = React.useState(null);
  const [knowledgeEntityEditor, setKnowledgeEntityEditor] = React.useState(null);
  const [knowledgeEntityMove, setKnowledgeEntityMove] = React.useState(null);
  const [linkedItemEditor, setLinkedItemEditor] = React.useState(null);
  const [deleteLink, setDeleteLink] = React.useState(null);
  const [updateLink, setUpdateLink] = React.useState(null);
  const [moveLink, setMoveLink] = React.useState(null);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(null);
  const [uploadExternalLink, setUploadExternalLink] = React.useState(null);
  const { externalSyncProvider = null } = options ?? {};

  const expiryFilter = useExpiryFilterWithAdmin({ adminMode });

  const [notificationApi, contextHolder] = notification.useNotification();

  // Drag and Drop
  const mediaAreaGroup = `media`;
  const documentsAreaGroup = `documents`;
  const [, mediaDropArea] = useDrop({ accept: mediaAreaGroup });
  const [, documentsDropArea] = useDrop({ accept: documentsAreaGroup });

  const dragItem = (id, atIndex) => {
    const { item, index } = findItem(id);
    setData(
      update(data, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      }),
    );
  };

  const findItem = id => {
    const item = data.find(i => `${i.id}` === id);
    return {
      item,
      index: data.indexOf(item),
    };
  };

  const finishDrag = itemId => {
    updateKnowledgeItemsOrder({
      id,
      itemId,
      index: findItem(itemId).index,
    });
  };

  // Delete knowledge items
  const deleteKnowledgeAction = React.useCallback(({ id, title }) => {
    // TODO: analytics
    setDeleteKnowledgeItem({ id, title });
  }, []);

  const onFinishDelete = React.useCallback(() => {
    setDeleteKnowledgeItem(null);
  }, []);

  // Move knowledge items
  const moveKnowledgeAction = React.useCallback(({ id, title, ownerIds, thumbnail }) => {
    // TODO: analytics
    setKnowledgeEntityMove({ id, title, ownerIds, thumbnail });
  }, []);

  const onFinishMove = React.useCallback(() => {
    setKnowledgeEntityMove(null);
  }, []);

  // Editor
  const onFinishEditor = React.useCallback(() => {
    setKnowledgeEntityEditor(null);
  }, []);

  const editOrCreateKnowledge = React.useCallback(({ item = {} }) => {
    setKnowledgeEntityEditor(item);
  }, []);

  const createNewKnowledge = React.useCallback(
    (data = {}) => {
      setKnowledgeEntityEditor({
        ...data,
        tags,
        labels,
        subtitle,
        type: KNOWLEDGE_TYPES.CONTENT,
        parentOwnerIds: ownerIds,
      });
    },
    [tags, labels, subtitle, ownerIds],
  );

  // Link Items
  const linkKnowledge = React.useCallback(() => {
    setLinkedItemEditor({ parentOwnerIds: ownerIds });
  }, [ownerIds]);

  const onFinishLink = React.useCallback(() => {
    setLinkedItemEditor(null);
  }, []);

  const onDeleteLink = React.useCallback(
    ({ id: itemId, title }) => {
      setDeleteLink({ id: itemId, categoryId: id, title });
    },
    [id],
  );

  const onFinishDeleteLink = React.useCallback(() => {
    setDeleteLink(null);
  }, []);

  const onUpdateLink = React.useCallback(
    ({ itemId, linkOwnerIds }) => {
      setUpdateLink({ id: itemId, categoryId: id, linkOwnerIds });
    },
    [id],
  );

  const onFinishUpdateLink = React.useCallback(() => {
    setUpdateLink(null);
  }, []);

  const onMoveLink = React.useCallback(({ id, linkOwnerIds }) => {
    setMoveLink({ id, linkOwnerIds });
  }, []);

  const onFinishMoveLink = React.useCallback(() => {
    setMoveLink(null);
  }, []);

  const onUploadFile = React.useCallback(() => {
    setUploadFile({ categoryId: id, tags, labels, subtitle, parentOwnerIds: ownerIds });
  }, [ownerIds, tags, labels, subtitle, id]);

  const onFinishUploadFile = React.useCallback(() => {
    setUploadFile(null);
  }, []);

  const onOpenAddPopup = React.useCallback(() => {
    setOpenAddPopup(true);
  }, []);

  const onFinishOpenAddPopup = React.useCallback(() => {
    setOpenAddPopup(false);
  }, []);

  const onExternalUpload = React.useCallback(
    (data = {}) => {
      setKnowledgeEntityEditor({
        ...data,
        ownerIds,
        tags,
        labels,
        subtitle,
        parentOwnerIds: ownerIds,
      });
    },
    [ownerIds, tags, labels, subtitle],
  );
  const onUploadExternalLink = React.useCallback(
    (data = {}) => {
      setUploadExternalLink({
        ...data,
        ownerIds,
        tags,
        labels,
        subtitle,
        type: KNOWLEDGE_TYPES.EXTERNAL_LINK,
      });
    },
    [ownerIds, tags, labels, subtitle],
  );
  const onFinishExternalUpload = React.useCallback(() => {
    setUploadExternalLink(null);
  }, []);

  const close = React.useCallback(() => {
    setOpenState(false);
    knowledgeSelectItem(null);
  }, [knowledgeSelectItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterByType = thumbnail => {
    if (searchType === SEARCH_TYPES_KEYS.none) return false;

    return searchType !== SEARCH_TYPES_KEYS.documents ? thumbnail === '' : !!thumbnail;
  };

  React.useEffect(() => {
    let filteredData = knowledgeItems;

    if (searchTerm || searchType) {
      filteredData = filteredData.filter(
        ({ title, subtitle, thumbnail, keywords }) =>
          !filterByType(thumbnail) &&
          (normalizedContains(title, searchTerm) ||
            normalizedContains(subtitle, searchTerm) ||
            !!keywords.find(key => normalizedContains(key, searchTerm))),
      );
    }

    setData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchType]);

  React.useEffect(() => {
    if (id) {
      setOpenState(true);
      setData(knowledgeItems);
      setSearchTerm('');
      setSearchType(SEARCH_TYPES_KEYS.none);
    }
  }, [id, knowledgeItems, isOpen]);

  React.useEffect(() => {
    const searchId = new URLSearchParams(search).get(routingParam);

    if (!searchId && isOpen) {
      setOpenState(false);
    }
  }, [search, isOpen, routingParam]);

  let grouped = _.groupBy(data, item => (item.thumbnail ? 'media' : 'documents'));

  const renderTags = () => {
    if (!tags?.contentType && !tags?.specialty) return null;

    return (
      <div className={styles.tagsContainer}>
        <TagsIcons tags={tags} hideTags={{ targetAudience: true, contentType: true }} large />
        <TextTag tag={tags?.contentType} large />
      </div>
    );
  };

  const renderList = (items, dndGroup = '') => {
    return items.filter(expiryFilter).map(item => {
      if (item.linked) {
        return (
          <KnowledgeWrapperComponent
            key={`${item.id}-link`}
            item={item}
            linkOwnerIds={item.linkOwnerIds}
            categoryId={id}
            adminMode={adminMode}
            onDelete={onDeleteLink}
            onUpdate={onUpdateLink}
            onMove={onMoveLink}
            linkedItem
            {...{
              dragItem,
              findItem,
              dndGroup,
              finishDrag,
            }}
          />
        );
      }

      return (
        <div className={styles.row} key={item.id}>
          <KnowledgeWrapperComponent
            item={item}
            categoryId={id}
            onDelete={deleteKnowledgeAction}
            onUpdate={editOrCreateKnowledge}
            onMove={moveKnowledgeAction}
            adminMode={adminMode}
            {...{
              dragItem,
              findItem,
              dndGroup,
              finishDrag,
            }}
            {...itemConfig}
          />
        </div>
      );
    });
  };

  return (
    <>
      <div className={classnames(styles.root, isOpen ? styles.open : undefined)}>
        {loading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <Tooltip title={langTooltip.CLOSE} placement={'bottom'}>
              <div onClick={close} className={styles.close}>
                <img src={Cross} alt="Close" />
              </div>
            </Tooltip>

            <div className={styles.tagIconsWrapper}>
              <div className={styles.header}>
                {showExternalSync && (
                  <img
                    className={styles.externalSyncIcon}
                    src={getExternalSyncIcon(externalSyncProvider)}
                    alt={externalSyncProvider}
                  />
                )}
                <div className={styles.text}>
                  <div className={styles.titleWrapper}>
                    {title && <h2>{title}</h2>}
                    <ShareBoxComponent
                      path={sharePath ?? `${ROUTES.KNOWLEDGE}?${routingParam}=${id}`}
                      id={id}
                      title={title}
                      tags={tags}
                      entityType={CATEGORY_TYPES.KNOWLEDGE}
                    />
                  </div>
                  {showExternalSync && (
                    <h4 className={styles.disclaimer}>{lang.EXTERNAL_SYNC_DISCLAIMER}</h4>
                  )}
                </div>
              </div>
              {renderTags()}
            </div>

            <div className={styles.searchWrapper}>
              <Search className={styles.icon} color="#193f6a" />
              <input
                className={styles.input}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            <SearchType searchType={searchType} onChange={setSearchType} />

            {adminMode && (
              <div className={styles.buttons}>
                <Button
                  size="small"
                  onClick={onOpenAddPopup}
                  buttonStyle="primary"
                  className={styles.leftButton}
                  data-key={DATA_KEYS.KNOWLEDGE_BASE.ADD.BUTTON}
                >
                  {lang.ADD}
                </Button>
              </div>
            )}
            {openAddPopup && (
              <KnowledgeAddPopupComponent
                close={onFinishOpenAddPopup}
                onCreate={createNewKnowledge}
                onLink={linkKnowledge}
                onUpload={onUploadFile}
                onExternalLink={onUploadExternalLink}
                title={title}
                ownerIds={ownerIds}
              />
            )}

            <div className={styles.knowledgeGroups}>
              {grouped.documents && (
                <div ref={documentsDropArea}>
                  <h4 className={styles.category}>{langTypes.DOCUMENTS}</h4>
                  {renderList(grouped.documents, documentsAreaGroup)}
                </div>
              )}

              {grouped.media && (
                <div ref={mediaDropArea}>
                  <h4 className={styles.category}>{langTypes.MEDIA}</h4>
                  {renderList(grouped.media, mediaAreaGroup)}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {deleteKnowledgeItem && (
        <KnowledgeDeletePopup entity={deleteKnowledgeItem} onFinish={onFinishDelete} />
      )}
      {knowledgeEntityEditor && (
        <KnowledgeContentComponent
          categoryId={id}
          {...knowledgeEntityEditor}
          close={onFinishEditor}
          edit={true}
        />
      )}

      {knowledgeEntityMove && (
        <KnowledgeMovePopup categoryId={id} item={knowledgeEntityMove} onFinish={onFinishMove} />
      )}

      {linkedItemEditor && (
        <KnowledgeLinkEditor categoryId={id} onFinish={onFinishLink} parentOwnerIds={ownerIds} />
      )}

      {deleteLink && (
        <KnowledgeLinkDeletePopup entity={deleteLink} onDismiss={onFinishDeleteLink} />
      )}

      {updateLink && (
        <KnowledgeLinkUpdatePopup
          linkOwnerIds={updateLink.linkOwnerIds}
          entity={updateLink}
          onDismiss={onFinishUpdateLink}
        />
      )}

      {moveLink && (
        <KnowledgeLinkMovePopup
          categoryId={id}
          item={moveLink}
          onFinish={onFinishMoveLink}
          enableCreateLink={false}
        />
      )}

      {uploadFile && (
        <KnowledgeUploadFileComponent
          {...uploadFile}
          categoryId={id}
          ownerIds={ownerIds}
          close={onFinishUploadFile}
          editContent={setKnowledgeEntityEditor}
          editExternal={onExternalUpload}
          notificationApi={notificationApi}
        />
      )}

      {uploadExternalLink && (
        <KnowledgeUploadExternalLinkComponent
          {...uploadExternalLink}
          close={onFinishExternalUpload}
          editExternal={onExternalUpload}
        />
      )}
      {contextHolder}
    </>
  );
});

KnowledgeBinderComponent.defaultProps = {
  knowledgeItems: [],
  title: '',
  ownerIds: [],
  knowledgeSelectItem: () => {},
  lastUpdated: Date.now(),
  fullPath: null,
  initialOpenState: false,
  adminMode: false,
  updateKnowledgeItemsOrder: () => {},
  lang: Lang.KNOWLEDGE_BUTTONS,
  langTypes: Lang.SEARCH_TYPES,
  langTooltip: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { KnowledgeBinderComponent };
