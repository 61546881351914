import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { colorPalette, theme } from 'shared/constants';
import { useKnowledgeParser } from 'shared/utils/rotations';
import { Button } from 'src/components/design-system';
import styles from './resource-manager.module.scss';
import { ConfigProvider, Modal } from 'antd';
import { SelectContent } from './select-content';
import { ResourceList } from './resource-list.component';

const ResourceManager = ({
  resources = [],
  canEdit = false,
  notFoundText = '',
  onAddResource = () => {},
  onRemoveResource = () => {},
  analyticsTriggers = {},
  modalTitle = '',
  disabledResourceIds = [],
  contextHolder,
  lang = {},
  title,
  showNotFoundPanda = false,
}) => {
  const knowledge = useSelector(state => state.knowledge);
  const admission = useSelector(state => state.admission);
  const lab = useSelector(state => state.lab);
  const { parse } = useKnowledgeParser({ knowledge, admission, lab });

  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleAdd = useCallback(
    async payload => {
      setLoading(true);

      await onAddResource(payload);
      analyticsTriggers?.add?.(payload);

      setLoading(false);
    },
    [onAddResource, analyticsTriggers],
  );

  return (
    <div className={styles.content}>
      {contextHolder}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: colorPalette.neutral2,
              headerBg: colorPalette.neutral2,
              fontFamily: theme.newFontFamily,
              fontSizeHeading2: theme.modalHeaderFontSize,
            },
          },
        }}
      >
        <Modal
          open={isModalOpen}
          footer={null}
          width={1000}
          centered
          maskClosable={false}
          destroyOnClose
          closable={false}
          title={<h2 className={styles.modalTitle}>{modalTitle}</h2>}
        >
          <SelectContent
            disabledIds={disabledResourceIds}
            onCancel={handleCloseModal}
            onSubmit={handleAdd}
            loading={loading}
          />
        </Modal>
      </ConfigProvider>

      <div className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {canEdit && (
          <Button type="default" size={title ? 'small' : 'middle'} onClick={handleOpenModal}>
            {lang.ADD_RESOURCE}
          </Button>
        )}
      </div>

      <ResourceList
        resources={resources.map(parse)}
        canEdit={canEdit}
        notFoundText={notFoundText}
        doRemove={onRemoveResource}
        analyticsTriggers={analyticsTriggers}
        showNotFoundPanda={showNotFoundPanda}
      />
    </div>
  );
};

export default ResourceManager;
