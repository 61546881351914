import styles from './schedule-urls-table.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

import { Button } from 'src/components/design-system';

import { colorPalette } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';

import _ from 'lodash';
import { MODES } from '../workspace-general-info';
import { Popconfirm, Table } from 'antd';

/**
 * Schedule Url table component
 * @param {object} props
 * @param {import('../../types').ScheduleUrl[]} props.scheduleUrls the schedule urls list
 * @param {(index: number, mode: 'create' | 'edit') => void} props.openScheduleUrlEditor function to open the schedule url editor
 * @param {(index: number) => Promise<void>} props.onDeleteScheduleUrl function to delete the schedule url
 */
export function ScheduleUrlTable({ scheduleUrls, openScheduleUrlEditor, onDeleteScheduleUrl }) {
  const lang = useSelector(getLang('SETTINGS'));
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));

  if (!scheduleUrls?.length) {
    return null;
  }

  const renderCommaList = list => {
    return list?.map(val => LANG_PROFILE[val]).join(', ') || '';
  };

  const handleDelete = async index => {
    await onDeleteScheduleUrl(index);
  };

  const columns = [
    {
      title: lang.QGENDA_TITLE_LABEL,
      dataIndex: 'title',
      key: 'title',
      render: (text, record, index) => (
        <p>{text || _.take(record.url.replace(/^https?:\/\//, ''), 20)}</p>
      ),
    },
    {
      title: lang.PROFESSIONS,
      dataIndex: 'professions',
      key: 'professions',
      render: professions => renderCommaList(professions),
    },
    {
      title: lang.ROLES,
      dataIndex: 'roles',
      key: 'roles',
      render: roles => renderCommaList(roles),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: <></>,
      width: '10%',
      render: (text, record, index) => (
        <div className={styles.actions} key={index}>
          <Button
            type="text"
            onClick={() => openScheduleUrlEditor(index, MODES.EDIT)}
            icon={<FontAwesomeIcon icon={faPencil} />}
          />
          <Popconfirm
            title={lang.DELETE_SCHEDULE_URL}
            cancelText={lang.CANCEL}
            okText={lang.DELETE}
            okType="danger"
            onConfirm={() => handleDelete(index)}
            okButtonProps={{ type: 'primary' }}
            cancelButtonProps={{ type: 'default' }}
          >
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faTrashCan} color={colorPalette.red} />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={scheduleUrls}
      className={styles.scheduleUrlsTable}
    />
  );
}
