import { List as AntdList, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { colorPalette } from 'shared/constants';
import styles from './list.module.scss';

/**
 * @typedef {import('antd').ListProps & {
 *   onItemSelected: (id: string, index: number) => void,
 *   selected: string,
 *   labelTextProperty: string,
 * }} ListProps
 */

/**
 * List component
 * @param {ListProps} params
 * @param params.onItemSelected - The function to call when an item is selected
 * @param params.selected - The id of the selected item
 * @param params.labelTextProperty - The property of the item to use as the label
 * @param params.props - The rest of the props
 */
export function List({ onItemSelected, selected, labelTextProperty, ...props }) {
  const renderItem = (item, index) => {
    const isSelected = item.id === selected;
    return (
      <button
        key={item.id}
        className={classNames(styles.item, { [styles.itemSelected]: isSelected })}
        onClick={() => onItemSelected(item.id, index)}
      >
        <p
          className={classNames(styles.itemTitle, {
            [styles.itemSelectedText]: isSelected,
          })}
        >
          {item[labelTextProperty]}
        </p>
      </button>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          List: {
            colorText: `${colorPalette.primary} !important`,
            colorTextBase: colorPalette.primary,
          },
        },
      }}
    >
      <AntdList renderItem={renderItem} {...props} />
    </ConfigProvider>
  );
}
