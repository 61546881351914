import React from 'react';
import classNames from 'classnames';
import { PlusIcon } from '../../../images';
import styles from './action-button.module.scss';
import { Tooltip } from '../../core';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { Lang } from 'shared/constants';

const ActionButton = React.memo(
  ({
    onClick = () => {},
    labelKey = 'ADD_SECTION',
    className = {},
    iconClassName = {},
    disabled = false,
    icon: Icon = PlusIcon,
    labelText = null,
    positionAbsolute = true,
    renderIcon = null,
  }) => {
    const lang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));
    const label = labelText || lang[labelKey] || Lang.ACTION_BUTTON_TOOLTIPS[labelKey] || '';

    const renderButton = () => (
      <button
        className={classNames(
          styles.createItem,
          className,
          positionAbsolute ? styles.positionAbsolute : '',
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {renderIcon ? renderIcon : <Icon className={classNames(styles.icon, iconClassName)} />}
      </button>
    );

    if (disabled) {
      return renderButton();
    }

    return (
      <Tooltip placement="topRight" title={label}>
        {renderButton()}
      </Tooltip>
    );
  },
);

export { ActionButton };
