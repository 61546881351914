import {
  ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  COLLABORATOR_PERMISSIONS_GROUP,
  DEFAULT_USER_PERMISSION,
  ROOT_PERMISSIONS_GROUP,
  VIEWER_PERMISSIONS_GROUP,
} from '../../constants';

export const getPermission = ({ auth }) => {
  const { tokenDetails } = auth;

  return tokenDetails.permissions || DEFAULT_USER_PERMISSION;
};

export const hasPermission = ({ auth, permissions = VIEWER_PERMISSIONS_GROUP }) => {
  const { tokenDetails } = auth;

  return permissions.includes(tokenDetails.permissions || DEFAULT_USER_PERMISSION);
};

export const isCollaborator = ({ auth }) => {
  return hasPermission({ auth, permissions: COLLABORATOR_PERMISSIONS_GROUP });
};

export const isAccountAdmin = ({ auth }) => {
  return hasPermission({ auth, permissions: ACCOUNT_ADMIN_PERMISSIONS_GROUP });
};

export const isRoot = ({ auth }) => {
  return hasPermission({ auth, permissions: ROOT_PERMISSIONS_GROUP });
};
