import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import 'antd/dist/reset.css';
import 'draft-js/dist/Draft.css';
import './styles/main.scss';
import { history, store } from './store/store';
import { ErrorBoundary } from './components/errors/error-boundary.component';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App history={history} />
    </ErrorBoundary>
  </Provider>,
);
