import React from 'react';
import {
  ROUTES,
  INTERNAL_ROUTING,
  EVENTS,
  FAVORITES_ENTITIES,
  CATEGORY_TYPES,
} from 'shared/constants';
import { useHistory, useLocation } from 'react-router';
import { useCallback } from 'react';
import analyticsService from '../../../helpers/analytics.service';
import { notification } from 'antd';
import { Lang } from 'shared/constants';
import { ConfirmationPopupComponent } from '../../../components/common/confirmation-popup';
import { UserList } from 'src/components/user-list';
import { ENTITY, useColumns } from 'src/components/user-list/use-columns.hooks';
import styles from './contacts.module.scss';
import { ShareBoxComponent } from 'src/components/share';
import { FavoritesButton } from 'src/components/favorites-button';
import { ContactDetailsComponent } from 'src/components/contact-details';

export const ITEM_SIZE = 74;

const ContactsComponent = React.memo(function ContactsComponent({
  lang = Lang.CONTACT_SCREEN,
  contacts,
  deleteContact,
}) {
  const [details, setDetails] = React.useState({});
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [deleteContactEntity, setDeleteContactEntity] = React.useState(null);
  const history = useHistory();
  const { search } = useLocation();

  const renderActions = contact => (
    <div className={styles.actionIcons}>
      <button type="button" className={styles.buttonWrapper}>
        <ShareBoxComponent
          path={ROUTES.CONTACTS_PREVIEW.replace(':id', contact.id)}
          id={contact.id}
          title={contact?.firstName}
          entityType={CATEGORY_TYPES.KNOWLEDGE}
          iconClassName={styles.actionIcon}
        />
      </button>
      <FavoritesButton
        id={contact.id}
        entity={contact.isUser ? FAVORITES_ENTITIES.USER : FAVORITES_ENTITIES.CONTACT}
        background={styles.transparentBackground}
        wrapper={styles.buttonWrapper}
        className={styles.actionIcon}
      />
    </div>
  );

  const columns = useColumns({ entity: ENTITY.CONTACT, renderActions });

  React.useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const id = urlParams.get(INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS);
    if (id) {
      const contact = contacts.find(contact => contact.id === id);
      setDetails(contact);
      setDetailsOpen(true);
    } else {
      setDetails({});
    }
  }, [search, contacts]);

  const openNotification = useCallback(
    ({ message, type = 'success' }) => {
      api[type]({
        message,
        placement: 'bottomRight',
        duration: 10,
      });
    },
    [api],
  );

  const addContact = useCallback(() => {
    setDetails({});
    setDetailsOpen(true);
  }, []);

  const closeDetailsSidebar = useCallback(() => {
    history.push(ROUTES.CONTACTS);
    setDetailsOpen(false);
  }, [history]);

  const openPreview = useCallback(
    id => {
      history.push(ROUTES.CONTACTS_PREVIEW.replace(':id', id));
    },
    [history],
  );

  const onDeleteContact = useCallback(contact => setDeleteContactEntity(contact), []);
  const doDeleteContact = useCallback(async () => {
    const res = await deleteContact({ id: deleteContactEntity.id });

    if (!res?.error) {
      openNotification({ message: lang.NOTIFICATION_DELETE_SUCCESS });
      setDeleteContactEntity(null);
      closeDetailsSidebar();
    }
  }, [deleteContactEntity, deleteContact, openNotification, lang, closeDetailsSidebar]);

  const cancelDeleteContact = useCallback(() => setDeleteContactEntity(null), []);

  const renderDetails = () => (
    <ContactDetailsComponent
      contact={details}
      close={closeDetailsSidebar}
      open={detailsOpen}
      openNotification={openNotification}
      onDeleteContact={onDeleteContact}
    />
  );

  return (
    <>
      <UserList
        users={contacts}
        columns={columns}
        pageTitle={lang.TITLE}
        buttonText={lang.ADD_NEW_CONTACT}
        onAdd={addContact}
        onDelete={onDeleteContact}
        openNotification={openNotification}
        notificationContextHolder={contextHolder}
        renderDetails={renderDetails}
        analyticsTriggers={{
          openDetails: payload => analyticsService.track(EVENTS.CONTACT.OPEN_DETAILS, payload),
        }}
        openPreview={openPreview}
      />
      {deleteContactEntity && (
        <ConfirmationPopupComponent
          title={lang.DELETE_CONTACT_POPUP_TITLE}
          message={lang.DELETE_CONTACT_POPUP_TEXT.replace(
            '{name}',
            `${deleteContactEntity?.firstName} ${deleteContactEntity?.lastName || ''}`,
          )}
          confirmMessage={lang.DELETE_CONTACT_POPUP_CONFIRM}
          dismissMessage={lang.DELETE_CONTACT_POPUP_CANCEL}
          onConfirm={doDeleteContact}
          onDismiss={cancelDeleteContact}
        />
      )}
    </>
  );
});

export { ContactsComponent };
