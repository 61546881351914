import React, { useCallback, useEffect } from 'react';
import { useSearchResultsPreparation } from 'shared/hooks/search.hooks';
import { colorPalette, SEARCH_TYPES_KEYS } from 'shared/constants';
import styles from './select-content.module.scss';
import { TextInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system';
import { Divider } from 'antd';
import { KnowledgeItemRow } from '../knowledge';
import { LabItemRow } from '../lab';
import { ChecklistItemRow } from '../admission';
import { CategoryItemRow } from './category-item-row.component';
import * as Yup from 'yup';
import { NotFound } from 'src/components/common';
import { useKnowledgeParser } from 'shared/utils/rotations';

const url = Yup.string().url().required('Required!');

const SelectContentComponent = ({
  doSearch,
  disabledIds,
  loading,
  lang,
  onCancel = () => {},
  onSubmit = () => {},
  knowledge,
  admission,
  lab,
}) => {
  const [selected, setSelected] = React.useState({});
  const [results, setResults] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [newTitle, setNewTitle] = React.useState(selected.title || '');
  const { prepareResults } = useSearchResultsPreparation({}, null);
  const isSelected = React.useCallback(
    ({ id, workspaceId }) => selected.id === id && selected.workspaceId === workspaceId,
    [selected],
  );
  const isSelectActive = React.useCallback(({ id }) => !!selected?.id, [selected]);
  const { compose } = useKnowledgeParser({ knowledge, admission, lab });
  const isDisabled = React.useCallback(id => disabledIds.includes(id), [disabledIds]);
  const [notFound, setNotFound] = React.useState(lang.START_TYPING);

  useEffect(() => {
    (async function () {
      setSelected({});
      setNewTitle('');

      const search = doSearch(filter, {});
      const { resultsCount, labItems, ...restResults } = prepareResults(search.results, true, true);

      const sortedResults = Object.values(restResults)
        .flat()
        .sort((a, b) => {
          if (a.score === undefined) return 1;
          if (b.score === undefined) return -1;
          return a.score - b.score;
        });
      setResults(sortedResults);

      if (resultsCount === 0 && filter) {
        try {
          await url.validate(filter);
          setNotFound(lang.ADD_EXTERNAL_URL.replace('{url}', filter));
        } catch (e) {
          setNotFound(lang.NO_RESULTS.replace('{term}', filter));

          return;
        }
        setNewTitle(filter);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSubmit = useCallback(async () => {
    const itemToAdd = { ...selected, title: newTitle, url: selected?.id ? undefined : filter };
    onSubmit(compose(itemToAdd));
    setSelected({});
    setNewTitle('');
    setFilter('');
    setNotFound(lang.START_TYPING);
  }, [onSubmit, selected, newTitle, filter, compose, lang.START_TYPING]);

  const handleSelect = useCallback(item => {
    setSelected(item);
    setNewTitle(item.title);
  }, []);

  const clearSelected = useCallback(() => {
    setSelected({});
    setNewTitle('');
  }, []);

  const renderByType = React.useMemo(
    () => ({
      [SEARCH_TYPES_KEYS.documents]: ({ item }, i) => (
        <KnowledgeItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
          hide={isSelectActive(item) && !isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.compendium]: ({ item }, i) => (
        <LabItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
          hide={isSelectActive(item) && !isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.admission]: ({ item }, i) => (
        <ChecklistItemRow
          key={i}
          item={item}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
          hide={isSelectActive(item) && !isSelected(item)}
        />
      ),
      [SEARCH_TYPES_KEYS.category]: ({ item, type }, i) => (
        <CategoryItemRow
          key={i}
          item={item}
          type={type}
          onSelect={handleSelect}
          disabled={isDisabled(item.id)}
          selected={isSelected(item)}
          hide={isSelectActive(item) && !isSelected(item)}
        />
      ),
    }),

    [isSelected, handleSelect, isDisabled, isSelectActive],
  );

  return (
    <div className="new-design-system">
      <Divider dashed style={{ borderColor: colorPalette.neutral5, margin: '15px 0' }} />
      <TextInput
        label={lang.SEARCH_OR_PASTE}
        onChange={e => setFilter(e.target.value)}
        value={filter}
        className="new-design-system"
        allowClear
      />
      <TextInput
        label={lang.ITEM_TITLE}
        onChange={e => setNewTitle(e.target.value)}
        value={newTitle}
        className="new-design-system"
        allowClear
      />
      <div className={styles.results}>
        {results.length > 0 ? (
          <>
            {results.map(({ type, item }, i) => renderByType[type]?.({ item, type }, i))}
            {selected.id && (
              <Button className={styles.clearSelected} onClick={clearSelected}>
                {lang.CLEAR_SELECTED}
              </Button>
            )}
          </>
        ) : (
          <NotFound text={notFound} className={styles.notFound} />
        )}
      </div>
      <Divider dashed style={{ borderColor: colorPalette.neutral5, margin: '15px 0' }} />
      <div className={styles.buttons}>
        <Button onClick={onCancel} disabled={loading}>
          {lang.CANCEL}
        </Button>
        <Button type="primary" onClick={handleSubmit} disabled={!newTitle} loading={loading}>
          {lang.SUBMIT}
        </Button>
      </div>
    </div>
  );
};

export { SelectContentComponent };
