import { ConfigProvider, Tabs as AntdTabs } from 'antd';
import { Divider } from '../separators';
import styles from './tabs.module.scss';
import classNames from 'classnames';

/**
 * Tabs component
 * @param {import('antd').TabsProps} param0
 */
export function Tabs({ style, ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorBorderSecondary: '#D9D9D9',
            titleFontSize: '1rem',
          },
        },
      }}
    >
      <>
        <Divider style={{ marginBottom: 0, marginTop: '18px' }} />
        <AntdTabs
          defaultActiveKey="1"
          {...props}
          style={{ fontWeight: '500', minHeight: 0, overflowY: 'auto', ...style }}
          className={classNames(props.className, styles.tabs)}
        />
      </>
    </ConfigProvider>
  );
}
