import React, { useCallback, useState } from 'react';
import styles from './user-profile.module.scss';
import { UserProfile } from '../../../components/user-profile';
import { useSelector } from 'react-redux';
import { needToUpdateProfile } from 'shared/store/selectors/user.selector';

const UserProfilePopup = () => {
  const shouldShowPopup = useSelector(({ auth, generalConfig }) =>
    needToUpdateProfile({ auth, generalConfig }),
  );
  const [showPopup, setShowPopup] = useState(shouldShowPopup);

  const onClose = useCallback(() => {
    setShowPopup(false);
  }, []);

  return showPopup ? (
    <div className={styles.popup}>
      <UserProfile isPopup onClose={onClose} />
    </div>
  ) : null;
};

export { UserProfilePopup };
