import React from 'react';
import { PandaNotFound } from 'src/images';
import styles from './error-page.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';

const GenericErrorPage = () => {
  const lang = useSelector(getLang('ERROR_PAGES'));
  return (
    <div className={classNames(['new-design-system', styles.root])}>
      <img src={PandaNotFound} alt="Sad Panda" className={styles.panda} />
      <span>{lang.UNEXPECTED_ERROR}</span>
    </div>
  );
};

export { GenericErrorPage };
