import { Col, Row } from 'antd';
import { Divider } from '../design-system';
import styles from './profile-layout.module.scss';

const ProfileLayoutComponent = ({
  renderTopNotification = () => {},
  renderTopSection = () => {},
  renderMainSection = () => {},
  renderBottomSection = () => {},
  renderFooter = () => null,
}) => {
  return (
    <>
      {renderTopNotification()}
      <div className={styles.root}>
        <Row>{renderTopSection()}</Row>
        <Row>
          <Col span={18}>
            <Divider />
            <Row gutter={50}>{renderMainSection()}</Row>
            {renderFooter()}
            <Divider />
          </Col>
          <Col span={18}>{renderBottomSection()}</Col>
        </Row>
      </div>
    </>
  );
};
export { ProfileLayoutComponent };
