import {
  TAGS_SPECIALTY,
  TAGS_KNOWLEDGE_CONTENT_TYPE,
  TAGS_LAB_CONTENT_TYPE,
  TAGS_TARGET_AUDIENCE,
} from 'shared/constants';
import {
  AllergyImmunologyTag,
  AnesthesiologyTag,
  DermatologyTag,
  RadiologyTag,
  EmergencyMedTag,
  IntensiveCareTag,
  FamilyMedTag,
  InternalMedTag,
  NeurologyTag,
  CardiologyTag,
  NephrologyTag,
  ObstetricsTag,
  GynecologyTag,
  OphthalmologyTag,
  PathologyTag,
  PediatricsTag,
  PhysMedRehabTag,
  PsychiatryTag,
  OncologyTag,
  DigestiveSurgeryTag,
  UrologyTag,
  PneumologyTag,
  OrthopedicsTag,
  NeurosurgeryTag,
  EarsNoseThroatTag,
  SurgeryTag,
  InfectiousDiseasesTag,
  AngiologyTag,
  Covid19Tag,
  EndocrinologyTag,
  HematologyTag,
  HemostasisTag,
  PharmacologyTag,
  PrehospitalMedTag,
  MedicalDirectorateTag,
  ThoracicSurgeryTag,
  InterventionalCardiologyTag,
  PlasticReconstructiveSurgeryTag,
  GastroenterologyTag,
  EducationTag,
  AdministrativeTag,
  TechSkillsTag,
  PreGradTag,
  LocalProtocolsTag,
  InternationalGuidelinesTag,
  SchedulesTag,
  LabValuesTag,
  DrugDosageTag,
  ClinicalDecisionTag,
  DoctorsTag,
  NursesTag,
  ParamedicalTag,
  MidwifeTag,
  PhysiotherapistTag,
  EveryoneTag,
  QualityTag,
  TranplantationTag,
  PainMedicineTag,
  PerioperativeMedicine,
  CardiacSurgery,
  VascularSurgery,
  SexualHealthTag,
} from '../images/tags';

const tagsToIconsMapper = {
  //specialties
  [TAGS_SPECIALTY.ALLERGY_IMMUNOLOGY]: AllergyImmunologyTag,
  [TAGS_SPECIALTY.ANESTHESIOLOGY]: AnesthesiologyTag,
  [TAGS_SPECIALTY.DERMATOLOGY]: DermatologyTag,
  [TAGS_SPECIALTY.RADIOLOGY]: RadiologyTag,
  [TAGS_SPECIALTY.EMERGENCY_MEDECINE]: EmergencyMedTag,
  [TAGS_SPECIALTY.INTENSIVE_CARE]: IntensiveCareTag,
  [TAGS_SPECIALTY.FAMILY_MEDECINE]: FamilyMedTag,
  [TAGS_SPECIALTY.INTERNAL_MEDECINE]: InternalMedTag,
  [TAGS_SPECIALTY.NEUROLOGY]: NeurologyTag,
  [TAGS_SPECIALTY.CARDIOLOGY]: CardiologyTag,
  [TAGS_SPECIALTY.NEPHROLOGY]: NephrologyTag,
  [TAGS_SPECIALTY.OBSTETRICS]: ObstetricsTag,
  [TAGS_SPECIALTY.GYNECOLOGY]: GynecologyTag,
  [TAGS_SPECIALTY.OPHTHALMOLOGY]: OphthalmologyTag,
  [TAGS_SPECIALTY.PATHOLOGY]: PathologyTag,
  [TAGS_SPECIALTY.PEDIATRICS]: PediatricsTag,
  [TAGS_SPECIALTY.PHYSICAL_MEDECINE_REHAB]: PhysMedRehabTag,
  [TAGS_SPECIALTY.PSYCHIATRY]: PsychiatryTag,
  [TAGS_SPECIALTY.ONCOLOGY]: OncologyTag,
  [TAGS_SPECIALTY.UROLOGY]: UrologyTag,
  [TAGS_SPECIALTY.PNEUMOLOGY]: PneumologyTag,
  [TAGS_SPECIALTY.ORTHOPEDICS]: OrthopedicsTag,
  [TAGS_SPECIALTY.NEUROSURGERY]: NeurosurgeryTag,
  [TAGS_SPECIALTY.EARS_NOSE_THROAT]: EarsNoseThroatTag,
  [TAGS_SPECIALTY.SURGERY]: SurgeryTag,
  [TAGS_SPECIALTY.DIGESTIVE_SURGERY]: DigestiveSurgeryTag,
  [TAGS_SPECIALTY.INFECTIOUS_DISEASES]: InfectiousDiseasesTag,
  [TAGS_SPECIALTY.HEMATHOLOGY]: HematologyTag,
  [TAGS_SPECIALTY.ENDOCRINOLOGY]: EndocrinologyTag,
  [TAGS_SPECIALTY.PHARMACOLOGY]: PharmacologyTag,
  [TAGS_SPECIALTY.ANGIOLOGY]: AngiologyTag,
  [TAGS_SPECIALTY.HEMOSTASIS]: HemostasisTag,
  [TAGS_SPECIALTY.COVID_19]: Covid19Tag,
  [TAGS_SPECIALTY.PRE_HOSPITAL_EMERGENCY_MEDECINE]: PrehospitalMedTag,
  [TAGS_SPECIALTY.MEDICAL_DIRECTORATE]: MedicalDirectorateTag,
  [TAGS_SPECIALTY.THORACIC_SURGERY]: ThoracicSurgeryTag,
  [TAGS_SPECIALTY.INTERVENTIONAL_CARDIOLOGY]: InterventionalCardiologyTag,
  [TAGS_SPECIALTY.PLASTIC_RECONSTRUCTIVE_SURGERY]: PlasticReconstructiveSurgeryTag,
  [TAGS_SPECIALTY.GASTROENTEROLOGY]: GastroenterologyTag,
  [TAGS_SPECIALTY.QUALITY]: QualityTag,
  [TAGS_SPECIALTY.TRANSPLANTATION]: TranplantationTag,
  [TAGS_SPECIALTY.PAIN_MEDICINE]: PainMedicineTag,
  [TAGS_SPECIALTY.PERIOPERATIVE_MEDICINE]: PerioperativeMedicine,
  [TAGS_SPECIALTY.CARDIAC_SURGERY]: CardiacSurgery,
  [TAGS_SPECIALTY.VASCULAR_SURGERY]: VascularSurgery,
  [TAGS_SPECIALTY.CHILD_ADVOCACY]: PediatricsTag,
  [TAGS_SPECIALTY.CHILD_DEVELOPMENT]: PediatricsTag,
  [TAGS_SPECIALTY.NEONATOLOGY]: PediatricsTag,
  [TAGS_SPECIALTY.PRIMARY_CARE]: FamilyMedTag,
  [TAGS_SPECIALTY.INTEGRATIVE_MEDICINE]: FamilyMedTag,
  [TAGS_SPECIALTY.RESEARCH]: PathologyTag,
  [TAGS_SPECIALTY.GENETICS]: PathologyTag,
  [TAGS_SPECIALTY.GENERAL_SURGERY]: SurgeryTag,
  [TAGS_SPECIALTY.BEHAVIORAL_AND_MENTAL_HEALTH]: PsychiatryTag,
  [TAGS_SPECIALTY.SEXUAL_HEALTH_AND_FERTILITY]: SexualHealthTag,

  // knowledge content types
  [TAGS_KNOWLEDGE_CONTENT_TYPE.EDUCATION]: EducationTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.ADMINISTRATIVE]: AdministrativeTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.TECH_SKILLS]: TechSkillsTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.PRE_GRADUATE]: PreGradTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.LOCAL_PROTOCOLS]: LocalProtocolsTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.INTERNATIONAL_GUIDELINES]: InternationalGuidelinesTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.SCHEDULES]: SchedulesTag,
  [TAGS_KNOWLEDGE_CONTENT_TYPE.POLICIES_AND_PROCEDURES]: LocalProtocolsTag,
  // lab content types
  [TAGS_LAB_CONTENT_TYPE.LAB_VALUES]: LabValuesTag,
  [TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE]: DrugDosageTag,
  [TAGS_LAB_CONTENT_TYPE.CLINICAL_DECISION]: ClinicalDecisionTag,
  // target audiences
  [TAGS_TARGET_AUDIENCE.DOCTORS]: DoctorsTag,
  [TAGS_TARGET_AUDIENCE.NURSES]: NursesTag,
  [TAGS_TARGET_AUDIENCE.PARAMEDICAL]: ParamedicalTag,
  [TAGS_TARGET_AUDIENCE.PHYSIOTHERAPISTS]: PhysiotherapistTag,
  [TAGS_TARGET_AUDIENCE.MIDWIVES]: MidwifeTag,
  EVERYONE: EveryoneTag,
};

export const getTagIcon = tag => tagsToIconsMapper[tag];
