import React, { useCallback, useMemo } from 'react';
import { notification } from 'antd';
import { EVENTS } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';
import ResourceManager from 'src/components/core/select-content/resource-manager.component';

const ProfileResourceManager = ({
  lang,
  user,
  canEdit,
  addResourceToUser,
  removeResourceFromUser,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const userResourceIds = React.useMemo(() => {
    return user?.professionalProfile?.resources?.map(r => r?.id) || [];
  }, [user?.professionalProfile?.resources]);

  const showErrorToast = useCallback(() => {
    api.error({
      message: lang.ERROR_OCCURRED,
      placement: 'bottomRight',
    });
  }, [api, lang.ERROR_OCCURRED]);

  const showSuccessToast = useCallback(
    message => {
      api.success({
        message,
        placement: 'bottomRight',
      });
    },
    [api],
  );

  const doAdd = useCallback(
    async payload => {
      try {
        const res = await addResourceToUser({
          userId: user?.id,
          payload,
        });
        if (res.error) {
          console.error(res.error);
          showErrorToast();
        } else {
          showSuccessToast(lang.RESOURCE_ADDED);
        }
      } catch (e) {
        console.error(e);
        showErrorToast();
      }
    },
    [addResourceToUser, user?.id, showErrorToast, showSuccessToast, lang.RESOURCE_ADDED],
  );

  const doRemove = useCallback(
    async id => {
      try {
        const res = await removeResourceFromUser({ userId: user?.id, resourceId: id });
        if (res.error) {
          console.error(res.error);
          showErrorToast();
        } else {
          showSuccessToast(lang.RESOURCE_REMOVED);
        }
      } catch (e) {
        console.error(e);
        showErrorToast();
      }
    },
    [removeResourceFromUser, user?.id, showErrorToast, showSuccessToast, lang.RESOURCE_REMOVED],
  );

  const sendAnalytics = useCallback(
    (event, item) => {
      analyticsService.track(event, {
        userId: user?.id,
        userName: user?.name,
        ...item,
      });
    },
    [user],
  );

  const analyticsTriggers = useMemo(
    () => ({
      add: item => sendAnalytics(EVENTS.USER_PREFERENCES.ADD_RESOURCE, item),
      remove: item => sendAnalytics(EVENTS.USER_PREFERENCES.REMOVE_RESOURCE, item),
      open: item => sendAnalytics(EVENTS.USER_PREFERENCES.RESOURCE_OPEN, item),
    }),
    [sendAnalytics],
  );

  return (
    <ResourceManager
      resources={user?.professionalProfile?.resources || []}
      canEdit={canEdit}
      onAddResource={doAdd}
      onRemoveResource={doRemove}
      analyticsTriggers={analyticsTriggers}
      modalTitle={lang.ADD_RESOURCE}
      disabledResourceIds={userResourceIds}
      contextHolder={contextHolder}
      lang={lang}
      title={lang.USER_RESOURCES}
      notFoundText={lang.NO_RESOURCES_IN_USER}
    />
  );
};

export { ProfileResourceManager };
