import { faSpinner, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styles from './tab-team.module.scss';
import { formatFullName } from 'shared/utils';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';
import { ShareBoxComponent } from 'src/components/share';
import { EVENTS, ROUTES } from 'shared/constants';
import { Button } from 'src/components/design-system';
import { useHistory } from 'react-router';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { useRotationsContext } from '../../../rotations-context';
import { notification } from 'antd';
import analyticsService from 'src/helpers/analytics.service';

const TeamRow = ({ member, removable = false, group }) => {
  const history = useHistory();
  const { selectedRotation, lang, removeTeamMember, canEdit } = useRotationsContext();
  const { id, firstName, lastName, email, pager, phone, cisco, professional } = member || {};
  const [loadingRemove, setLoadingRemove] = useState(false);
  const { role = '', department = '' } = professional || {};
  const roleLang = useSelector(getLang('PROFILE_OPTIONS'));
  const departmentLang = useSelector(getLang('TAGS'));
  const [api, contextHolder] = notification.useNotification();

  const handleRemove = async id => {
    if (loadingRemove) return;
    setLoadingRemove(true);
    try {
      const res = await rotationsServiceApi.removeMemberFromTeam({
        rotationId: selectedRotation.id,
        group,
        memberId: id,
      });
      if (res.error) {
        api.error({
          message: res.data.message || lang.ERROR_OCCURRED,
          placement: 'bottomRight',
        });
        return;
      }

      removeTeamMember(id, group);
    } catch (err) {
      api.error({
        message: err.message || lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });
    } finally {
      setLoadingRemove(false);
    }
  };

  const professionalLabel = [roleLang[role], departmentLang[department]]
    .filter(v => v !== undefined)
    .join(', ');

  const shareURL = ROUTES.CONTACTS_PREVIEW.replace(':id', id);

  const onTeamMemberClicked = () => {
    analyticsService.track(EVENTS.ROTATIONS.TEAM_MEMBER_CLICKED, {
      rotationId: selectedRotation.id,
      rotationName: selectedRotation.name,
      group,
      memberId: id,
    });

    history.push(shareURL);
  };

  return (
    <>
      {contextHolder}
      <div className={styles.tableRow} key={id}>
        <div className={styles.columnValue}>
          <p className={classNames([styles.nameValue, styles.link])} onClick={onTeamMemberClicked}>
            {formatFullName({ firstName, lastName })}
          </p>
          {professionalLabel.length ? (
            <p className={styles.professionalValue}>{professionalLabel}</p>
          ) : null}
        </div>
        <p className={styles.columnValue}>{phone || '-'}</p>
        <p className={styles.columnValue}>{pager || '-'}</p>
        <p className={styles.columnValue}>{cisco || '-'}</p>
        <p className={styles.columnValue}>
          {email ? (
            <a href={`mailto:${email}`} className={styles.link}>
              {email}
            </a>
          ) : (
            '-'
          )}
        </p>
        <div className={styles.actionColumn}>
          <Button type="link" className={styles.buttonIcon}>
            <ShareBoxComponent
              iconClassName={classNames([styles.icon, styles.shareIcon])}
              path={shareURL}
            />
          </Button>
          {canEdit && removable && (
            <Button type="link" onClick={() => handleRemove(id)} className={styles.buttonIcon}>
              <FontAwesomeIcon
                icon={loadingRemove ? faSpinner : faXmark}
                spin={loadingRemove}
                className={classNames([styles.icon, styles.removeIcon])}
              />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export { TeamRow };
