import styles from './workspace-general-info.module.scss';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { getLang } from 'shared/store/selectors/lang.selector';

import { Button, Divider, TextField } from 'src/components/design-system';
import { Modal, notification } from 'antd';
import { QuickLinkEditor } from '../quick-link-editor';
import { ScheduleUrlEditor } from '../schedule-url-editor';
import {
  WORKSPACE_SCHEMA_CALENDAR_URL,
  WORKSPACE_SCHEMA_NAME,
} from '../workspace-editor/workspace.schema';
import { useWorkspaceApi } from '../../hooks';
import { useWorkspacesContext, WORKSPACE_SEARCH_PARAMS } from '../../hooks/use-workspaces-context';
import { ScheduleUrlTable } from '../schedule-urls-table';
import { QuickLinkBox } from '../quick-link-box';

export const MODES = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit',
});

/**
 * General info tab of the workspace
 * @returns
 */
export function WorkspaceGeneralInfo() {
  const { workspace, onChangeWorkspace, isNew } = useWorkspacesContext();
  const { updateName, updateOperational } = useWorkspaceApi();
  const lang = useSelector(getLang('SETTINGS'));

  const [api, contextHolder] = notification.useNotification({
    placement: 'bottomRight',
  });

  const { scheduleUrls = [], quickLinks = [], calendarUrl = '' } = workspace?.operational || {};

  const postUpdate = async updatedWorkspace => {
    if (updatedWorkspace.error) {
      api.error({
        message: updatedWorkspace.error,
      });
    } else {
      api.success({
        message: lang.WORKSPACE_UPDATED,
      });
    }
    onChangeWorkspace(updatedWorkspace);
  };

  const onUpdateName = async name => {
    if (isNew) {
      onChangeWorkspace({ ...workspace, name }, { setSearchParams: true });
      return;
    }
    const updatedWorkspace = await updateName(workspace.id, name);

    postUpdate(updatedWorkspace);
  };

  const onUpdateOperational = async operational => {
    if (isNew) {
      onChangeWorkspace({ ...workspace, operational }, { setSearchParams: true });
      return;
    }

    const updatedWorkspace = await updateOperational(workspace.id, {
      operational,
      name: workspace.name,
    });

    postUpdate(updatedWorkspace);
  };

  const quickLinkEditorState = useEditorState({
    searchParamKey: WORKSPACE_SEARCH_PARAMS.QUICK_LINK_INDEX,
    items: quickLinks,
    onUpdate: async quickLinks => {
      await onUpdateOperational({ ...workspace.operational, quickLinks });
    },
    onDelete: async quickLinks => {
      await onUpdateOperational({ ...workspace.operational, quickLinks });
    },
  });

  const scheduleUrlEditorState = useEditorState({
    searchParamKey: WORKSPACE_SEARCH_PARAMS.SCHEDULE_URL_INDEX,
    items: scheduleUrls,
    onUpdate: async scheduleUrls => {
      await onUpdateOperational({ ...workspace.operational, scheduleUrls });
    },
    onDelete: async scheduleUrls => {
      await onUpdateOperational({ ...workspace.operational, scheduleUrls });
    },
  });

  return (
    <>
      {contextHolder}
      <div className={styles.root}>
        <section className={styles.fields}>
          <TextField
            key={`${workspace.id}-name`}
            label={lang.WORKSPACE_NAME}
            initialValue={workspace.name}
            placeholder={lang.UNTITLED_WORKSPACE}
            name="name"
            applyChanges={onUpdateName}
            schema={WORKSPACE_SCHEMA_NAME}
            canEdit
            initialEditing={isNew}
            withLoading
            lang={lang}
          />

          <TextField
            key={`${workspace.id}-calendarUrl`}
            label={lang.CALENDER_URL}
            initialValue={calendarUrl}
            name="calendarUrl"
            applyChanges={async calendarUrl =>
              await onUpdateOperational({ ...workspace.operational, calendarUrl })
            }
            schema={WORKSPACE_SCHEMA_CALENDAR_URL}
            canEdit
            withLoading
            lang={lang}
          />
        </section>

        <Divider dashed />

        <section className={styles.scheduleUrlsWrapper}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>{lang.QGENDA_SECTION_TITLE}</p>
            <Button
              size="small"
              onClick={() => scheduleUrlEditorState.openEditor(scheduleUrls.length, MODES.CREATE)}
            >
              {lang.ADD_QGENDA}
            </Button>
          </div>

          <div className={styles.scheduleUrlsList}>
            <ScheduleUrlTable
              scheduleUrls={scheduleUrls}
              onDeleteScheduleUrl={scheduleUrlEditorState.deleteItem}
              openScheduleUrlEditor={scheduleUrlEditorState.openEditor}
            />
          </div>
        </section>

        <Divider dashed />

        <section className={styles.quickLinksWrapper}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>{lang.QUICK_LINK_TITLE}</p>
            <Button
              size="small"
              onClick={() => quickLinkEditorState.openEditor(quickLinks.length, MODES.CREATE)}
            >
              {lang.ADD_QUICK_LINK}
            </Button>
          </div>

          <div className={styles.quickLinksList}>
            {quickLinks.map((quickLink, index) => (
              <QuickLinkBox
                key={`${quickLink.iconUrl}-${index}`}
                quickLink={quickLink}
                index={index}
                onDeleteQuickLink={quickLinkEditorState.deleteItem}
                openQuickLinkEditor={quickLinkEditorState.openEditor}
              />
            ))}
          </div>
        </section>
      </div>

      <Modal
        title={
          <h2 className={styles.modalTitle}>
            {quickLinkEditorState.selectedMode === MODES.CREATE
              ? lang.ADD_QUICK_LINK
              : lang.EDIT_QUICK_LINK}
          </h2>
        }
        open={quickLinkEditorState.isEditorOpen}
        onCancel={quickLinkEditorState.closeEditor}
        centered
        footer={null}
        closeIcon={null}
      >
        <QuickLinkEditor
          key={quickLinkEditorState.selectedIndex}
          quickLink={quickLinkEditorState.selectedItem}
          onSubmit={quickLinkEditorState.updateItem}
          onCancel={quickLinkEditorState.closeEditor}
          mode={quickLinkEditorState.selectedMode}
        />
      </Modal>

      <Modal
        key={scheduleUrlEditorState.selectedIndex}
        title={
          <h2 className={styles.modalTitle}>
            {scheduleUrlEditorState.selectedMode === MODES.CREATE
              ? lang.ADD_QGENDA
              : lang.EDIT_QGENDA}
          </h2>
        }
        open={scheduleUrlEditorState.isEditorOpen}
        onCancel={scheduleUrlEditorState.closeEditor}
        centered
        footer={null}
        closeIcon={null}
      >
        <ScheduleUrlEditor
          key={scheduleUrlEditorState.selectedIndex}
          scheduleUrl={scheduleUrlEditorState.selectedItem}
          onSubmit={scheduleUrlEditorState.updateItem}
          onCancel={scheduleUrlEditorState.closeEditor}
          mode={scheduleUrlEditorState.selectedMode}
        />
      </Modal>
    </>
  );
}

/**
 *
 * @param {object} props
 * @param {string} props.searchParamKey - the key of the search param
 * @param {Array} props.items - the items to edit
 * @param {(items: Array) => Promise<void>} props.onUpdate - the function to call when updating any item
 * @param {(index: number) => Promise<void>} props.onDelete - the function to call when deleting any item
 *
 * @returns {{
 * selectedMode: string,
 * selectedIndex: string,
 * isEditorOpen: boolean,
 * selectedItem: object,
 * openEditor: (index: number, mode: string) => void,
 * closeEditor: () => void,
 * updateItem: (item: object) => Promise<void>,
 * deleteItem: (index: number) => Promise<void>,
 * }}
 */
function useEditorState({ searchParamKey, items, onUpdate, onDelete }) {
  const [selected, setSelected] = useSearchParams();

  const isEditorOpen = useMemo(
    () => selected.get(searchParamKey) !== null,
    [selected, searchParamKey],
  );

  const selectedItem = useMemo(() => {
    const index = selected.get(searchParamKey);
    return index !== null ? items[Number(index)] : null;
  }, [selected, searchParamKey, items]);

  const openEditor = (index, mode) => {
    setSelected(
      prev => {
        prev.set(searchParamKey, index);
        prev.set(WORKSPACE_SEARCH_PARAMS.MODE, mode);
        return prev;
      },
      { replace: true, relative: true },
    );
  };

  const closeEditor = () => {
    setSelected(
      prev => {
        prev.delete(searchParamKey);
        prev.delete(WORKSPACE_SEARCH_PARAMS.MODE);
        return prev;
      },
      { replace: true, relative: true },
    );
  };

  const updateItem = async item => {
    const indexParam = selected.get(searchParamKey);
    const mode = selected.get(WORKSPACE_SEARCH_PARAMS.MODE);

    if (indexParam === null || mode === null) {
      return;
    }

    const index = Number(indexParam);

    const updatedItems = [...items];

    if (mode === MODES.CREATE) {
      updatedItems.push(item);
    } else if (index < updatedItems.length) {
      updatedItems[index] = item;
    }

    await onUpdate(updatedItems);
    closeEditor();
  };

  const deleteItem = async index => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    await onDelete(updatedItems);
  };

  return {
    selectedMode: selected.get(WORKSPACE_SEARCH_PARAMS.MODE),
    selectedIndex: selected.get(searchParamKey),
    isEditorOpen,
    selectedItem,
    openEditor,
    closeEditor,
    updateItem,
    deleteItem,
  };
}
