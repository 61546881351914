import { Dropdown as AntdDropdown, ConfigProvider } from 'antd';
import styles from './dropdown.module.scss';
import { colorPalette } from 'shared/constants';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

/**
 * @typedef {import('antd').DropdownProps & {
 *  items: import('antd').MenuProps['items'],
 *  onSelect: (key: string) => void,
 *  activeKey: string,
 *  showArrowIcon?: boolean,
 *  bordered?: boolean,
 *  childrenWrapperStyle?: Record<string, any>,
 * }} DropdownProps
 */

/**
 * Dropdown component
 * @param {DropdownProps} props
 *
 * @example
 * // Basic usage with bordered style
 * import { Dropdown } from './Dropdown';
 *
 * const items = [
 *   { label: 'Option 1', key: 'option1' },
 *   { label: 'Option 2', key: 'option2' },
 *   { label: 'Option 3', key: 'option3' },
 * ];
 *
 * const handleSelect = (key) => {
 *   console.log(`Selected: ${key}`);
 * };
 *
 * <Dropdown
 *   items={items}
 *   onSelect={handleSelect}
 *   activeKey="option1"
 *   bordered
 * >
 *   Select an Option
 * </Dropdown>;
 *
 * @example
 * // Dropdown with arrow icon and custom styles
 * import { Dropdown } from './Dropdown';
 *
 * const items = [
 *   { label: 'Menu Item 1', key: 'item1', style: { fontWeight: 'bold' } },
 *   { label: 'Menu Item 2', key: 'item2' },
 * ];
 *
 * <Dropdown
 *   items={items}
 *   onSelect={(key) => console.log(`Selected: ${key}`)}
 *   activeKey="item2"
 *   showArrowIcon
 * >
 *   Open Menu
 * </Dropdown>;
 *
 * @example
 * // Controlling visibility with `hide` and managing the `open` state
 *   const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
 *   const otherDialog = useRef(null);
 *
 *   const handleDropdownOpenChange = (open) => {
 *     if (!open) {
 *       otherDialog.current.closeDialog();
 *     }
 *     setSettingsDropdownOpen(open);
 *   };
 *
 *   const items = [
 *     { key: 'settings', label: <NavLink to="/settings">Settings</NavLink>, hide: false },
 *     { key: 'feedback', label: 'Give Feedback', onClick: () => console.log('Feedback') },
 *     {
 *       key: 'qr_code',
 *       label: <div ref={otherDialog}>Other Dialog</div>,
 *       style: { padding: 0 },
 *       onClick: (e) => e.domEvent.preventDefault(),
 *     },
 *   ];
 *
 *   return (
 *     <Dropdown
 *       items={items}
 *       open={settingsDropdownOpen}
 *       onOpenChange={handleDropdownOpenChange}
 *     >
 *       <div>
 *         Open Menu
 *       </div>
 *     </Dropdown>
 *   );
 */
export function Dropdown({
  items,
  onSelect,
  activeKey,
  showArrowIcon = false,
  bordered = false,
  childrenWrapperStyle = {},
  disabled = false,
  ...props
}) {
  const defaultItems = useMemo(
    () =>
      items
        .filter(item => !item.hide)
        .map((item, index) => ({
          ...item,
          style: {
            maxWidth: '18rem',
            padding: '0.625rem 1rem',
            borderRadius: 0,
            borderBottom:
              index < items.length - 1 ? `1px solid ${colorPalette.primaryMain1}` : 'none',
            ...item.style,
          },
        })),
    [items],
  );

  const defaultMenu = useMemo(
    () =>
      /**
       * @type {import('antd').MenuProps}
       */
      ({
        ...props.menu,
        items: defaultItems,
        onClick: ({ key }) => {
          if (onSelect) onSelect(key);
        },
        selectable: true,
        defaultSelectedKeys: [activeKey],
        selectedKeys: [activeKey],
        style: {
          maxHeight: '36rem',
          maxWidth: '18rem',
          overflow: 'auto',
          padding: 0,
          ...props.menu?.style,
        },
      }),
    [defaultItems, props.menu, onSelect, activeKey],
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            controlItemBgHover: colorPalette.primaryMain1,
            colorText: colorPalette.primaryMain7,
          },
        },
      }}
    >
      <AntdDropdown
        placement="bottomLeft"
        trigger={'hover'}
        disabled={disabled}
        {...props}
        menu={defaultMenu}
      >
        <div
          className={classNames(
            styles.childrenWrapper,
            {
              [styles.bordered]: bordered,
              [styles.disabled]: disabled,
              [styles.showArrowIcon]: showArrowIcon,
            },
            childrenWrapperStyle,
          )}
        >
          {props.children}
          {showArrowIcon && (
            <FontAwesomeIcon
              icon={faAngleDown}
              color={colorPalette.neutral1}
              className={styles.arrowIcon}
            />
          )}
        </div>
      </AntdDropdown>
    </ConfigProvider>
  );
}
