import { connect } from 'react-redux';
import { PROFILE_TYPES, UserProfileComponent } from './user-profile.component';
import { updateUserData, snoozeProfile } from 'shared/store/actions/auth.actions';
import { isProfileMandatory } from 'shared/store/selectors/user.selector';
import { isTermsAndConditionsEnabled } from 'shared/store/selectors/features.selector';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';
import { MY_PROFILE_SCHEMA } from './schemas';
import { EVENTS } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';

const mapStateToProps = ({ auth, deviceFlags, generalConfig, organization }) => {
  return {
    user: auth?.user,
    selectedLang: deviceFlags?.LANGUAGE?.toUpperCase() ?? 'EN',
    isMandatory: isProfileMandatory({ auth, generalConfig }),
    showTermsAndConditions: isTermsAndConditionsEnabled({ organization, auth }),
  };
};

const UserProfileComponentWrapper = ({ ...props }) => {
  const onUpdate = async ({ id, data }) => await props.updateUserData({ id, user: data });

  const analyticsTriggers = {
    openProfile: payload => analyticsService.track(EVENTS.USER_PROFILE.OPEN, payload),
    completeProfile: payload => analyticsService.track(EVENTS.USER_PROFILE.COMPLETE, payload),
    updateProfile: payload => analyticsService.track(EVENTS.USER_PROFILE.UPDATE, payload),
  };

  return (
    <RotationOptionsProvider>
      <UserProfileComponent
        {...props}
        schema={MY_PROFILE_SCHEMA}
        type={PROFILE_TYPES.SELF}
        onUpdate={onUpdate}
        analyticsTriggers={analyticsTriggers}
      />
    </RotationOptionsProvider>
  );
};

export const UserProfile = connect(mapStateToProps, { updateUserData, snoozeProfile })(
  UserProfileComponentWrapper,
);
