import { AUTHENTICATION_TYPE, ORGANIZATIONS_LOGO_URL, ROUTES } from 'shared/constants';
import { LogoWhite } from 'src/images';
import { Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import styles from './switch-organization-dropdown.module.scss';
import OAuthConfirm from './oauth-confirmation-modal';
import OTPConfirm from './otp-confirmation-modal';
import authenticationServiceApi from 'shared/api/authentication.service.api';
import { useHistory } from 'react-router';
import { Dropdown } from 'src/components/design-system';
import { sortByAlphabet } from 'shared/utils';

const SwitchOrganizationDropdownComponent = ({
  activeOrganization,
  organizations = [],
  switchOrganization,
  lang,
}) => {
  const history = useHistory();
  const [organization, setOrganization] = useState(null);
  const [oAuthModalOpen, setOAuthModalOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const items = useMemo(
    () => [
      ...organizations
        .sort((a, b) => sortByAlphabet(a.name, b.name))
        .map(org => ({
          label: !org.activated ? (
            <>
              <FontAwesomeIcon icon={faLock} className={styles.menuIcon} />
              {org.name}
            </>
          ) : (
            org.name
          ),
          key: org.id,
        })),
      {
        label: (
          <>
            <FontAwesomeIcon icon={faUserPlus} className={styles.menuIcon} />
            {lang.MENU_TITLE}
          </>
        ),
        key: 'add',
        style: {
          fontWeight: 'bold',
        },
        onClick: () => {
          setOtpModalOpen(true);
        },
      },
    ],
    [organizations, lang.MENU_TITLE],
  );

  const doSwitchOrganization = useCallback(() => {
    (async function () {
      if (organization.activated) {
        await switchOrganization({ id: organization.id });
        history.push(ROUTES.HOME);
        return;
      }
      if (organization.auth.type === AUTHENTICATION_TYPE.OAUTH) {
        setOAuthModalOpen(true);
        return;
      }

      setOtpModalOpen(true);
    })();
  }, [switchOrganization, organization, history]);

  React.useEffect(() => {
    if (organization) {
      doSwitchOrganization();
    }
  }, [organization, doSwitchOrganization]);

  const onSubmitEmail = async email => {
    return await authenticationServiceApi.activateOTPAccount({
      email,
      organizationId: organization?.id,
    });
  };

  const onVerifyCode = async ({ email, code }) => {
    await authenticationServiceApi.verifyActivateAccount({
      email,
      code,
      organizationId: organization?.id,
    });

    if (organization?.id) {
      switchOrganization({ id: organization?.id });
    }
  };

  return (
    <>
      <Modal
        open={oAuthModalOpen}
        onCancel={() => {
          setOAuthModalOpen(false);
          setOrganization(organizations.find(org => org.id === activeOrganization.id));
        }}
        width={700}
        footer={null}
        centered
        maskClosable={false}
        destroyOnClose
      >
        <OAuthConfirm organization={organization} />
      </Modal>
      <Modal
        open={otpModalOpen}
        onCancel={() => {
          setOtpModalOpen(false);
          setOrganization(organizations.find(org => org.id === activeOrganization.id));
        }}
        width={700}
        footer={null}
        centered
        maskClosable={false}
        destroyOnClose
      >
        <OTPConfirm submitEmailCallback={onSubmitEmail} verifyCodeCallback={onVerifyCode} />
      </Modal>

      <Dropdown
        items={items}
        onSelect={key => {
          const organization = organizations.find(org => org.id === key);
          setOrganization(organization);
        }}
        activeKey={activeOrganization.id}
        showArrowIcon
        bordered
        childrenWrapperStyle={styles.dropdownInnerWrapper}
      >
        <div className={styles.logoWrapper}>
          {activeOrganization.logo ? (
            <img
              src={ORGANIZATIONS_LOGO_URL + 'web/' + activeOrganization.logo}
              alt={activeOrganization.name}
              className={styles.logo}
            />
          ) : (
            <LogoWhite className={styles.logo} />
          )}
        </div>
      </Dropdown>
    </>
  );
};

export { SwitchOrganizationDropdownComponent };
