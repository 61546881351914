import React from 'react';
import { Button as AntdButton, ConfigProvider } from 'antd';
import { colorPalette, theme } from 'shared/constants';

/**
 * @typedef {import('antd').ButtonProps} ButtonProps
 */

/**
 * @param {ButtonProps} props
 * @returns {JSX.Element}
 */
export function Button({ children, type, ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            //type default
            colorBgContainer: 'transparent',
            colorPrimaryText: colorPalette.primaryMain5,
            colorPrimary: colorPalette.primaryMain5,
            colorPrimaryHover: colorPalette.primaryMain6,
            colorPrimaryTextHover: colorPalette.primaryMain6,
            colorBorder: colorPalette.primaryMain6,
            colorText: colorPalette.primaryMain6,
            colorBorderSecondary: colorPalette.primaryMain5,
            colorBorderSecondaryHover: colorPalette.primaryMain6,
            borderColorDisabled: colorPalette.neutral4,
            colorLinkHover: colorPalette.primaryMain5,
          },
        },
        token: {
          fontFamily: theme.newFontFamily,
        },
      }}
    >
      <AntdButton type={type === 'danger' ? 'default' : type} danger={type === 'danger'} {...props}>
        {children}
      </AntdButton>
    </ConfigProvider>
  );
}
