import React from 'react';
import styles from './admission-section-list.module.scss';
import { Loader } from 'src/components/core';
import _ from 'lodash';
import { Lang, ROUTES, CATEGORY_TYPES } from 'shared/constants';
import update from 'immutability-helper';
import { useHistory } from 'react-router';
import { AdmissionSection } from '../admission-section';
import { scrollTo } from 'src/utils';
import { ActionButton, NotFound } from 'src/components/common';
import { AdmissionCategoryEditor, AdmissionCategoryDelete } from '../../admission-category';
import { AdmissionGenerateReport } from '../admission-generate-report';
import { AddSectionButton } from 'src/components/common/add-section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroomWide, faFileSignature } from '@fortawesome/pro-regular-svg-icons';

const AdmissionSectionListComponent = React.memo(function AdmissionSectionListComponent({
  adminMode,
  list,
  isReady,
  fullPath,
  lang,
  deleteCategory,
  updateCategorySubcategories,
  selectValues,
  isHaveActiveChecklist,
  ownerIds,
  resetChecklist,
  id,
  tags,
  isUserIdentified = false,
}) {
  const [categoryEntity, setCategory] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [sections, setSections] = React.useState(list);
  const [isGeneratePDF, setGeneratePDF] = React.useState(false);

  React.useEffect(() => {
    setSections(list);
  }, [list]);

  let history = useHistory();

  const generatePdf = async () => {
    setGeneratePDF(true);
  };

  const onFinishGeneratePdf = async () => {
    setGeneratePDF(false);
  };

  const changeSectionOrder = (id, atIndex) => {
    const { section, index } = findSection(id);
    setSections(
      update(sections, {
        $splice: [
          [index, 1],
          [atIndex, 0, section],
        ],
      }),
    );
    // Update the server with the new index
    finishDragSection(id, atIndex);
  };

  const findSection = id => {
    const section = sections.find(i => i.id === id);
    return {
      section,
      index: sections.indexOf(section),
    };
  };

  const finishDragSection = (categoryId, index) => {
    setTimeout(() => scrollTo(categoryId), 300);
    updateCategorySubcategories({
      id,
      categoryId,
      index,
    });
  };

  const createCategoryAction = React.useCallback(
    ({ path, isSectionCreation = false, ownerIds, tags }) => {
      // TODO: add analytics
      setCategory({ ownerIds, path, isSectionCreation, tags });
    },
    [],
  );

  const editCategoryAction = React.useCallback(
    ({ id, title, ownerIds, path, isSectionCreation = false, tags, keywords, type }) => {
      // TODO: add analytics

      setCategory({ id, title, ownerIds, path, isSectionCreation, tags, keywords, type });
    },
    [],
  );

  const deleteCategoryAction = React.useCallback(({ id, title, type, path }) => {
    // TODO: add analytics

    setDeleteConfirmation({ id, title, type, path });
  }, []);

  const createSection = () => {
    // TODO: analytics
    createCategoryAction({ path: fullPath, isSectionCreation: true, ownerIds, tags });
  };

  const deleteFinish = React.useCallback(() => setDeleteConfirmation(null), []);
  const editorFinish = React.useCallback(
    ({ id, type, isSectionCreation = false }) => {
      setCategory(null);
      if (id && !isSectionCreation) {
        if (type === CATEGORY_TYPES.CHECKS) {
          selectValues(id);
        } else {
          history.push(ROUTES.ADMISSION_CATEGORIES.replace(':id', id));
        }
      }
    },
    [history, selectValues],
  );

  if (_.isNull(sections) || !isReady) {
    return <Loader />;
  }

  if (sections.length === 0 && !adminMode) {
    return <NotFound />;
  }

  const callToAction = sections.length === 0 && adminMode && !categoryEntity;

  return (
    <>
      {sections.map((section, index) => (
        <AdmissionSection
          createCategory={createCategoryAction}
          deleteCategory={deleteCategoryAction}
          editCategory={editCategoryAction}
          key={`content-item-${section.id}`}
          firstItem={index === 0}
          lastItem={index === sections.length - 1}
          index={index}
          changeSectionOrder={changeSectionOrder}
          section={section}
        />
      ))}

      {callToAction && <AddSectionButton onClick={createSection} />}
      {adminMode && !callToAction && <ActionButton onClick={createSection} />}
      {!adminMode && isUserIdentified && (
        <>
          <ActionButton
            onClick={generatePdf}
            renderIcon={
              <FontAwesomeIcon className={styles.actionIcon} icon={faFileSignature} size="xl" />
            }
            className={styles.generatePdf}
            disabled={!isHaveActiveChecklist || isGeneratePDF}
            labelKey="DOWNLOAD"
          />
          <ActionButton
            onClick={resetChecklist}
            className={styles.resetPDF}
            iconClassName={styles.resetPDFIcon}
            disabled={!isHaveActiveChecklist || isGeneratePDF}
            labelKey="RESET"
            renderIcon={
              <FontAwesomeIcon className={styles.actionIcon} icon={faBroomWide} size="xl" />
            }
          />
        </>
      )}

      {categoryEntity && (
        <AdmissionCategoryEditor onFinish={editorFinish} entity={categoryEntity} />
      )}

      {isGeneratePDF && <AdmissionGenerateReport onFinish={onFinishGeneratePdf} />}

      <AdmissionCategoryDelete
        entity={deleteConfirmation}
        deleteCategory={deleteCategory}
        onFinish={deleteFinish}
      />
    </>
  );
});

AdmissionSectionListComponent.defaultProps = {
  lang: Lang.CONTENT_LIST,
  list: null,
  isReady: true,
  fullPath: null,
  updateCategorySubcategories: () => {},
  selectValues: () => {},
  resetChecklist: () => {},
  id: null,
  ownerIds: [],
  isHaveActiveChecklist: false,
  tags: {},
};

export { AdmissionSectionListComponent };
