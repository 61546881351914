import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import organizationServiceApi from 'shared/api/organization.service.api';
import { notification, Table, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Loader, TagInput, Tooltip } from 'src/components/core';
import styles from './magic-link-management.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { Button, ScreenTitle } from 'src/components/design-system';
import { CopyToClipboard } from 'src/components/common/copy-to-clipboard';
import { ActionButton, Popup } from 'src/components/common';
import { useFormik } from 'formik';
import ipRegex from 'ip-regex';
import classNames from 'classnames';
import { AllSettingsButton } from '../../settings.page.component';

const { Column } = Table;

function hideLink(link) {
  return link.replace(/.(?=.{4})/g, '');
}

export function MagicLinkManagementPage() {
  const organizationId = useSelector(state => state.organization.id);
  const lang = useSelector(getLang('SETTINGS'));
  const [magicLinks, setMagicLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const { formatLongDateTime } = useDateFormatter();

  function validatePayload(values) {
    const errors = {};

    // Check if domains or IP ranges have at least one value

    if (!values.allowDomains.length && !values.allowIpRanges.length) {
      errors.allowDomains = 'At least one domain or IP address is required.';
      errors.allowIpRanges = 'At least one domain or IP address is required.';
    }

    // Validate domains
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    values.allowDomains.forEach(domain => {
      if (!domainPattern.test(domain)) {
        errors.allowDomains = errors.allowDomains || [];
        errors.allowDomains.push(`${domain} is not a valid domain.`);
      }
    });

    // Validate IP ranges and add CIDR if missing
    values.allowIpRanges.forEach(ip => {
      const [ipRange, cidr] = ip.split('/');
      if (!ipRegex({ exact: true }).test(ipRange)) {
        errors.allowIpRanges = errors.allowIpRanges || [];
        errors.allowIpRanges.push(`${ipRange} is not a valid IP address.`);
      }
      if (!cidr) {
        errors.allowIpRanges = errors.allowIpRanges || [];
        errors.allowIpRanges.push(`CIDR is missing for ${ipRange}.`);
      } else {
        if (cidr < 0 || cidr > 32) {
          errors.allowIpRanges = errors.allowIpRanges || [];
          errors.allowIpRanges.push(`${cidr} is not a valid CIDR range.`);
        }
      }
    });

    return errors;
  }

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await organizationServiceApi.getMagicLinks({ organizationId });

      if (response.error) {
        console.log('error', response.error);
        setMagicLinks([]);
      } else {
        setMagicLinks(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [organizationId]);

  const onSubmit = async () => {
    setEditLoading(true);
    try {
      const serviceMethod = selectedId
        ? organizationServiceApi.updateMagicLink
        : organizationServiceApi.generateMagicLink;

      const toastMessage = selectedId ? lang.LINK_UPDATED : lang.LINK_CREATED;

      const response = await serviceMethod({
        organizationId,
        magicLinkId: selectedId,
        allowDomains: values.allowDomains,
        allowIpRanges: values.allowIpRanges,
      });

      if (response.error) {
        api.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight',
        });
        return;
      }
      api.success({
        message: toastMessage,
        placement: 'bottomRight',
      });
    } catch (error) {
      api.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight',
      });
    } finally {
      closeModal();
      fetchData();
    }
  };

  const closeModal = () => {
    setSelectedId(null);
    setEditModalOpen(false);
    resetForm();
    setEditLoading(false);
  };

  const handleEdit = ({ id }) => {
    setSelectedId(id);
  };

  const { handleSubmit, setFieldValue, resetForm, values, errors, touched, isValid } = useFormik({
    enableReinitialize: true,
    validate: validatePayload,
    initialValues: {
      allowDomains: selectedId ? magicLinks.find(({ id }) => selectedId === id)?.allowDomains : [],
      allowIpRanges: selectedId
        ? magicLinks.find(({ id }) => selectedId === id)?.allowIpRanges
        : [],
    },
    onSubmit,
  });

  useEffect(() => {
    if (selectedId) {
      setEditModalOpen(true);
    }
  }, [selectedId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {contextHolder}
      {loading ? (
        <Loader />
      ) : magicLinks ? (
        <div className={classNames(styles.root, 'new-design-system')}>
          <AllSettingsButton />
          <Popup
            title={selectedId ? lang.UPDATE_LINK_TITLE : lang.NEW_LINK_TITLE}
            isOpen={editModalOpen}
            className={styles.popup}
          >
            <label className={styles.inputLabel}>{lang.ALLOWED_IP_RANGES}</label>
            <TagInput
              value={values.allowIpRanges}
              error={errors.allowIpRanges}
              touched={touched.allowIpRanges}
              onChange={setFieldValue}
              className={styles.tagInput}
              name="allowIpRanges"
            />

            <label className={styles.inputLabel}>{lang.ALLOWED_DOMAINS}</label>
            <label className={classNames(styles.inputLabel, styles.bold)}>
              {lang.WARNING_DOMAIN}
            </label>
            <TagInput
              value={values.allowDomains}
              error={errors.allowDomains}
              touched={touched.allowDomains}
              onChange={setFieldValue}
              className={styles.tagInput}
              name="allowDomains"
            />

            <div className={styles.actions}>
              <Button
                size="medium"
                onClick={closeModal}
                className={styles.cancelButton}
                disabled={editLoading}
              >
                {lang.CANCEL}
              </Button>
              <Button
                size="medium"
                type="primary"
                onClick={handleSubmit}
                disabled={!isValid || editLoading}
                className={styles.cancelButton}
              >
                {selectedId ? lang.UPDATE : lang.CREATE}
              </Button>
            </div>
          </Popup>
          <div className={styles.header}>
            <ScreenTitle title={lang.MAGIC_LINK} containerStyle={styles.title} />
            <ActionButton
              onClick={() => setEditModalOpen(true)}
              labelText={lang.NEW_LINK}
              positionAbsolute={false}
              className={styles.newLinkButton}
            />
          </div>
          <Table dataSource={magicLinks} className={styles.table}>
            <Column
              className={styles.column}
              title={lang.LINK}
              dataIndex="link"
              key="link"
              render={link => (
                <div className={styles.linkWrapper}>
                  <p>{hideLink(link)}</p>
                  <CopyToClipboard text={link}>
                    <Tooltip title={lang.COPY}>
                      <Button type="link">
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              )}
            />
            <Column
              className={styles.column}
              title={lang.ALLOWED_DOMAINS}
              dataIndex="allowDomains"
              key="allowDomains"
              render={domains => (
                <div className={styles.tagContainer}>
                  {domains.map(domain => (
                    <Tag key={domain}>{domain}</Tag>
                  ))}
                </div>
              )}
            />
            <Column
              className={styles.column}
              title={lang.ALLOWED_IP_RANGES}
              dataIndex="allowIpRanges"
              key="allowIpRanges"
              render={ips => (
                <div className={styles.tagContainer}>
                  {ips.map(ip => (
                    <Tag key={ip}>{ip}</Tag>
                  ))}
                </div>
              )}
            />
            <Column
              className={styles.column}
              title={lang.CREATED_AT}
              dataIndex="createdAt"
              key="createdAt"
              render={date => formatLongDateTime(date)}
            />
            <Column
              className={styles.column}
              title={lang.UPDATED_AT}
              dataIndex="updatedAt"
              key="updatedAt"
              render={date => formatLongDateTime(date)}
            />
            <Column
              className={styles.column}
              title={lang.ACTIONS}
              key="action"
              render={(_, record) => (
                <Tooltip title={lang.EDIT}>
                  <Button type="link" onClick={() => handleEdit(record)}>
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                </Tooltip>
              )}
            />
          </Table>
        </div>
      ) : null}
    </>
  );
}
