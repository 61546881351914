import React, { useCallback, useMemo } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { notification } from 'antd';
import { EVENTS } from 'shared/constants';
import { useRotationsContext } from '../rotations-context';
import analyticsService from 'src/helpers/analytics.service';
import ResourceManager from 'src/components/core/select-content/resource-manager.component';

const KnowledgeTab = () => {
  const {
    lang,
    selectedRotation: rotation,
    canEdit,
    removeResourceInRotation,
    addResourceInRotation,
  } = useRotationsContext();

  const [api, contextHolder] = notification.useNotification();

  const rotationResourceIds = React.useMemo(() => {
    return rotation?.knowledge?.map(k => k.id) || [];
  }, [rotation]);

  const showErrorToast = useCallback(() => {
    api.error({
      message: lang.ERROR_OCCURRED,
      placement: 'bottomRight',
    });
  }, [api, lang.ERROR_OCCURRED]);

  const showSuccessToast = useCallback(
    message => {
      api.success({
        message,
        placement: 'bottomRight',
      });
    },
    [api],
  );

  const doAdd = useCallback(
    async payload => {
      try {
        const res = await rotationsServiceApi.addKnowledge({
          rotationId: rotation?.id,
          payload,
        });
        if (res.error) {
          showErrorToast();
        } else {
          showSuccessToast(lang.RESOURCE_ADDED);
          addResourceInRotation(payload);
        }
      } catch (e) {
        showErrorToast();
      }
    },
    [addResourceInRotation, rotation?.id, showErrorToast, showSuccessToast, lang.RESOURCE_ADDED],
  );

  const doRemove = useCallback(
    async id => {
      try {
        const res = await rotationsServiceApi.removeKnowledge({ rotationId: rotation?.id, id });
        if (res.error) {
          showErrorToast();
        } else {
          showSuccessToast(lang.RESOURCE_REMOVED);
          removeResourceInRotation(id);
        }
      } catch (e) {
        showErrorToast();
      }
    },
    [
      removeResourceInRotation,
      rotation?.id,
      showErrorToast,
      showSuccessToast,
      lang.RESOURCE_REMOVED,
    ],
  );

  const sendAnalytics = useCallback(
    (event, item) => {
      analyticsService.track(event, {
        rotationId: rotation?.id,
        rotationName: rotation?.name,
        ...item,
      });
    },
    [rotation],
  );

  const analyticsTriggers = useMemo(
    () => ({
      add: item => sendAnalytics(EVENTS.ROTATIONS.ADD_RESOURCE, item),
      remove: item => sendAnalytics(EVENTS.ROTATIONS.DELETE_RESOURCE, item),
      open: item => sendAnalytics(EVENTS.ROTATIONS.RESOURCE_OPEN, item),
    }),
    [sendAnalytics],
  );

  return (
    <ResourceManager
      resources={rotation?.knowledge}
      canEdit={canEdit}
      notFoundText={lang.NO_RESOURCES_FOUND}
      onAddResource={doAdd}
      onRemoveResource={doRemove}
      analyticsTriggers={analyticsTriggers}
      modalTitle={lang.ADD_RESOURCE}
      disabledResourceIds={rotationResourceIds}
      contextHolder={contextHolder}
      lang={lang}
      showNotFoundPanda
    />
  );
};

export { KnowledgeTab };
