import _ from 'lodash';
import React from 'react';
import { Route as RouteComponent, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '../errors/error-boundary.component';

const Route = React.memo(
  ({
    component: Component,
    isAllowedRoute,
    redirectRoute,
    children,
    isHavePermissions = null,
    rootPath,
    ...rest
  }) => {
    return (
      <ErrorBoundary key={rest.path}>
        <RouteComponent
          {...rest}
          render={props => {
            if (isAllowedRoute) {
              return (
                <Redirect
                  to={{
                    pathname: redirectRoute,
                    state: { from: props.location },
                  }}
                />
              );
            }

            if (!_.isNull(isHavePermissions) && !isHavePermissions) {
              return (
                <Redirect
                  to={{
                    pathname: rootPath,
                    state: { from: props.location },
                  }}
                />
              );
            }

            if (children) {
              return children;
            }

            return <Component {...props}>{children}</Component>;
          }}
        />
      </ErrorBoundary>
    );
  },
);

export { Route };
