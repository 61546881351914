import React from 'react';
import { GenericErrorPage } from './error-page.component';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return this.props.fallback || <GenericErrorPage />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
