import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KNOWLEDGE_RESOURCES_TYPES } from 'shared/constants';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { Button } from 'src/components/design-system';
import { faSpinner, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import styles from './resource-manager.module.scss';

const ResourceItemComponent = ({
  parsedResource = {},
  canEdit = false,
  loading = false,
  handleRemove = () => {},
  onOpenExternalUrl = () => {},
  onClick = () => {},
}) => {
  const isExternalUrl = parsedResource?.type === KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL;

  const actions = canEdit ? (
    <Button
      type="link"
      onClick={e => handleRemove(e, parsedResource.id)}
      className={styles.buttonIcon}
    >
      <FontAwesomeIcon
        icon={loading === parsedResource.id ? faSpinner : faTrashAlt}
        spin={loading === parsedResource.id}
        className={styles.removeIcon}
      />
    </Button>
  ) : null;

  if (isExternalUrl) {
    return (
      <a
        href={parsedResource.navigateTo}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.externalUrl}
        onClick={() => onOpenExternalUrl(parsedResource.navigateTo, parsedResource)}
      >
        <CategoryRowComponent {...parsedResource} actions={actions} />
      </a>
    );
  }

  return (
    <CategoryRowComponent
      {...parsedResource}
      actions={actions}
      onClick={() => onClick(parsedResource.navigateTo, parsedResource)}
    />
  );
};

export { ResourceItemComponent };
