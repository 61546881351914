import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContactItemById } from 'shared/store/selectors/contact.selector';
import {
  PROFILE_TYPES,
  UserProfileComponent,
} from 'src/components/user-profile/user-profile.component';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';
import { updateContact } from 'shared/store/actions/contacts.actions';
import { updateUser } from 'shared/store/actions/users-management.actions';
import { CONTACT_SCHEMA, USER_SCHEMA } from 'src/components/user-profile/schemas';
import { Loader } from 'src/components/core';

const ContactEditPage = () => {
  const { id } = useParams();
  const contact = useSelector(({ contacts, usersManagement }) =>
    getContactItemById({ contacts, usersManagement }, id),
  );
  const dispatch = useDispatch();

  const onUpdateContact = async ({ id, data }) =>
    await dispatch(updateContact({ id, contact: data }));

  const onUpdateUser = async ({ id, data }) =>
    await dispatch(updateUser({ id, userData: { ...data, email: [data.email] } }));

  if (!contact) {
    return <Loader />;
  }

  const profileType = contact.isUser ? PROFILE_TYPES.USER : PROFILE_TYPES.CONTACT;

  const onUpdate = profileType === PROFILE_TYPES.USER ? onUpdateUser : onUpdateContact;
  const schema = profileType === PROFILE_TYPES.USER ? USER_SCHEMA : CONTACT_SCHEMA;

  // TODO: add analytics triggers

  return (
    <RotationOptionsProvider>
      <UserProfileComponent user={contact} type={profileType} schema={schema} onUpdate={onUpdate} />
    </RotationOptionsProvider>
  );
};

export { ContactEditPage };
