import * as Yup from 'yup';
import { ENVIRONMENT_KEYS, PROFESSIONS, ROLES_ALL } from 'shared/constants';
import { currentEnvironment } from 'shared/services/Environment';

export const WORKSPACE_SCHEMA_NAME = Yup.object().shape({
  name: Yup.string().min(2).required(),
});

export const WORKSPACE_SCHEMA_CALENDAR_URL = Yup.object().shape({
  calendarUrl: Yup.string().url(),
});

export const WORKSPACE_SCHEMA_SCHEDULE_URLS = Yup.object().shape({
  roles: Yup.array()
    .of(Yup.string().oneOf(Object.values(ROLES_ALL)))
    .default([]),
  professions: Yup.array()
    .of(Yup.string().oneOf(Object.values(PROFESSIONS)))
    .default([]),
  url: Yup.string().url().required(),
  title: Yup.string().min(2).default(''),
});

export const WORKSPACE_SCHEMA_QUICK_LINKS = Yup.object().shape({
  title: Yup.string().min(2).required(),
  url: Yup.string().url().required(),
  iconUrl:
    currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT
      ? Yup.string().not([null, '']).required('Icon URL is required')
      : Yup.string().url().required('Icon URL is required'),
});

export const WORKSPACE_COPY_SCHEMA = Yup.object().shape({
  workspaceId: Yup.string().required('Please select a workspace to copy from'),
  copyCalendarUrl: Yup.bool(),
  copyQuickLinks: Yup.bool(),
  copyScheduleUrls: Yup.bool(),
});

export const CREATE_WORKSPACE_SCHEMA = Yup.object().shape({
  name: WORKSPACE_SCHEMA_NAME.fields.name,
  operational: Yup.object().shape({
    calendarUrl: WORKSPACE_SCHEMA_CALENDAR_URL.fields.calendarUrl,
    quickLinks: Yup.array().of(WORKSPACE_SCHEMA_QUICK_LINKS),
    scheduleUrls: Yup.array().of(WORKSPACE_SCHEMA_SCHEDULE_URLS),
  }),
});
