export const config = {
  selector: 'textarea',
  menubar: false,
  branding: false,
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'wordcount',
    'table',
  ],
  toolbar: [
    `undo redo 
    | blocks 
    | bold italic forecolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat
    | table link image media preview `,
  ],
  table_toolbar:
    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  table_advtab: false,
  table_row_advtab: false,
  table_cell_advtab: false,
  media_dimensions: false,
  toolbar_mode: 'sliding',
  file_picker_types: 'file image media',
  video_template_callback: function (data) {
    // return as string template
    return `<video src="${data.source}" controls="controls"></video>`;
  },
  //TODO try to improve this
  content_css: '../../content.css',
  convert_urls: false,
  image_dimensions: false,
};
