import _ from 'lodash';
import { CATEGORY_TYPES } from '../../constants/knowledge.constants';
import { getParentIdByPath } from '../../utils';
import workspaceService from '../../services/workspace.service';
import { getUser } from './user.selector';
import { hasPermissionsToEditItem } from './iam.selector';

const filterByWorkspace = (item, workspaceId) =>
  item?.workspaceId ? item.workspaceId === workspaceId : true;

export const getByIdCategory = (category, idToSearch) => {
  if (category.children && category.children.length !== 0) {
    let result;
    category.children.forEach(item => {
      if (result) return false;
      let res = getByIdCategory(item, idToSearch);

      if (res) result = res;
    });

    return result;
  }

  if (category.knowledge_item_medias && category.knowledge_item_medias !== 0) {
    return category.knowledge_item_medias.find(({ id }) => idToSearch === id);
  }
};

export const getAdmissionRoot = ({ categories }, initialWorkspaceId = null) => {
  //support orgs with and without workspaces
  if (!workspaceService.hasWorkspaces()) {
    if (!categories) return {};
    return categories.find(category => category.path?.toLowerCase() === 'root');
  }

  let workspaceId = initialWorkspaceId;

  if (!workspaceId) {
    workspaceId = workspaceService.getSelectedWorkspaceId();
  }

  if (!categories || !workspaceId) return {};
  return (
    categories.find(
      category => category.path?.toLowerCase() === 'root' && category.workspaceId === workspaceId,
    ) ?? {}
  );
};

export const getCategoriesByPath = ({ categories, categoriesById }, path) => {
  if (_.isNull(categories)) return null;

  let parentId = getParentIdByPath(path);

  return getAdmissionCategoriesById({ categoriesById }, parentId);
};

export const getAdmissionCategoriesById = ({ categoriesById }, id) => {
  if (_.isNull(categoriesById)) return null;

  if (!categoriesById[id]) return null;

  return categoriesById[id].subcategories.map(i =>
    getAdmissionCategoryById({ categoriesById }, { id: i }),
  );
};

export const getAdmissionCategoryById = ({ categoriesById }, { id }) => {
  return categoriesById?.[id] || {};
};

export const getItemById = ({ itemsById }, { id }) => {
  return itemsById[id] || null;
};

/**
 * Check if the category is a section
 * if path contains root, sections are items that have an even number of path parts
 * if path does not contain root, sections are items that have an odd number of path partss
 * @param {*} pathParts
 * @returns boolean
 */
export const isSection = (pathParts = []) => {
  const hasRoot = pathParts?.includes(new RegExp(/ROOT/gi));
  return hasRoot ? pathParts?.length % 2 !== 0 : pathParts?.length % 2 === 0;
};

export const generateBreadcrumbs = (
  { categoriesById, categories },
  { pathParts = [], initialWorkspaceId = null },
) => {
  const rootId = getAdmissionRoot({ categories }, initialWorkspaceId)?.id;

  return pathParts
    ? pathParts
        .filter(part => part.toLowerCase() !== 'root' && part !== rootId)
        .map(id => categoriesById[id])
    : [];
};

export const getLocations = ({ categories, categoriesById }, { type = 'category', path }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();
  const filteredCategories = categories.filter(category => category?.workspaceId === workspaceId);
  const root = getAdmissionRoot({ filteredCategories });

  let locations = [];

  // check if need to section or category
  if (!isSection(path?.split(','))) {
    locations = filteredCategories.filter(
      i => i.type === type && isSection(i.pathParts) && !!i.path,
    );

    let root = getAdmissionRoot({ filteredCategories });
    locations.unshift({ fullPath: root.id, title: root.title });
  } else {
    locations = filteredCategories.filter(i => i.type === type && i.pathParts?.length % 2 !== 0);
  }

  return locations
    .filter(locations => {
      // filter out locations that have either 'root' or 'root,<id_of_root>' as path
      const rootPathRegex = new RegExp(`^root(,${root.id})?$`);
      return !rootPathRegex.test(locations.path);
    })
    .map(item => {
      let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
        t => t?.title,
      );

      path = [...path, item.title].reverse();

      // Remove knowledge base root
      if (path.length > 1) {
        path.pop();
      }

      return {
        id: item.fullPath,
        value: path.join(' | '),
      };
    });
};

export const getLocationsOfCategories = ({ categories, categoriesById }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();

  let locations = categories
    .filter(category => filterByWorkspace(category, workspaceId))
    .filter(i => i.type === CATEGORY_TYPES.CHECKS);

  return locations.map(item => {
    let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
      t => t?.title,
    );

    path = [...path, item.title].reverse();

    // Remove knowledge base root
    if (path.length > 1) {
      path.pop();
    }

    return {
      id: item.id,
      value: path.join(' | '),
    };
  });
};

export const getComment = ({ activeChecklist }, { categoryId, id }) => {
  if (activeChecklist[categoryId] && activeChecklist[categoryId][id]) {
    return activeChecklist[categoryId][id].comment || '';
  }
  return '';
};

export const getCheckedState = ({ activeChecklist }, { categoryId, id }) => {
  if (activeChecklist[categoryId] && activeChecklist[categoryId][id]) {
    return activeChecklist[categoryId][id]?.checked;
  }
  return false;
};

export const getAdmissionParentsByItemId = ({ categories }, { id }) => {
  return categories.filter(i => i.type === 'checklist' && i.checks.find(check => check.id === id));
};

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {{ id: ObjectId }} { id } Lab entity id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
export function isChecklistOwner(state, { id }) {
  if (!id) return false;

  const permissionCheck = hasPermissionsToEditItem(state);

  // Check if the user has permission to bypass the owner restriction
  if (permissionCheck) return true;

  // Get user id
  const { id: userId } = getUser(state);

  return _checkChecklistChildrenOwnership(state, id, userId);
}

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {ObjectId} id - category id
 * @param {ObjectId} userId - user id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
function _checkChecklistChildrenOwnership(state, id, userId) {
  // get category by id
  const item = getAdmissionCategoryById(state.admission, { id });

  // check if the category is owned by the user
  if (!item.ownerIds.includes(userId)) {
    return false;
  }

  if (item.type === CATEGORY_TYPES.CATEGORY) {
    // recursively check if all of the subcategories are owned by the user
    return item.subcategories.every(subcategoryId => {
      return _checkChecklistChildrenOwnership(state, subcategoryId, userId);
    });
  }

  return true;
}
