import styles from './quick-link-box.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

import { ImageOverlay } from 'src/components/common';
import { Popconfirm } from 'antd';

import { colorPalette } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenClip } from '@fortawesome/pro-light-svg-icons';
import { MODES } from '../workspace-general-info';

/**
 * Quick link box component
 * @param {object} props
 * @param {import('../../types').QuickLink} props.quickLink the quick link object
 * @param {number} props.index the index of the quick link
 * @param {(index: number, mode: 'create' | 'edit') => void} props.openQuickLinkEditor function to open the quick link editor
 * @param {(index: number) => Promise<void>} props.onDeleteQuickLink function to delete the quick link
 */
export function QuickLinkBox({ quickLink, index, openQuickLinkEditor, onDeleteQuickLink }) {
  const lang = useSelector(getLang('SETTINGS'));

  const onDelete = async () => {
    await onDeleteQuickLink(index);
  };

  return (
    <div className={styles.quickLink}>
      <ImageOverlay
        overlayClassName={styles.quickLinkOverlayWrapper}
        imgClassName={styles.iconUrl}
        img={<img src={quickLink.iconUrl} alt={quickLink.title} />}
        overlay={
          <div className={styles.quickLinkOverlay}>
            <FontAwesomeIcon
              onClick={() => openQuickLinkEditor(index, MODES.EDIT)}
              className={styles.editQuickLink}
              icon={faPenClip}
              size="2x"
              color={colorPalette.neutral1}
            />
            <Popconfirm
              title={lang.DELETE_QUICK_LINK}
              cancelText={lang.CANCEL}
              okText={lang.DELETE}
              okType="danger"
              onConfirm={onDelete}
              okButtonProps={{ type: 'primary' }}
              cancelButtonProps={{ type: 'default' }}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                color={colorPalette.neutral1}
                className={styles.deleteQuickLink}
              />
            </Popconfirm>
          </div>
        }
      />
      <span>{quickLink.title}</span>
    </div>
  );
}
