import styles from './quick-link-editor.module.scss';
import { useFormik } from 'formik';
import { Button, Divider, TextInput } from 'src/components/design-system';
import { WORKSPACE_SCHEMA_QUICK_LINKS } from '../workspace-editor/workspace.schema';
import { MODES } from '../workspace-general-info';
import { FileUpload } from 'src/components/core';
import { SUPPORTED_IMG_FORMATS } from 'shared/constants/assets.constants';
import { uploadDocument } from 'shared/store/actions/knowledge.items.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

/**
 * Quick link editor component
 * @param {object} props
 * @param {import('../../types').QuickLink} props.quickLink the quick link object
 * @param {(values: import('../../types').QuickLink) => Promise<void>} props.onSubmit function to submit the quick link
 * @param {() => void} props.onCancel function to cancel the quick link
 * @param {'create' | 'edit'} props.mode the mode of the editor
 */
export function QuickLinkEditor({ quickLink, onSubmit, onCancel, mode }) {
  const lang = useSelector(getLang('SETTINGS'));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileUploadRef = useRef(null);

  const applyChanges = async (values, { resetForm }) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    resetForm();
  };

  const { values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm } = useFormik({
    initialValues: !quickLink
      ? {
          url: '',
          title: '',
          iconUrl: '',
        }
      : { ...quickLink },
    validationSchema: WORKSPACE_SCHEMA_QUICK_LINKS,
    enableReinitialize: true,
    onSubmit: applyChanges,
  });

  const handleCancel = () => {
    resetForm();
    fileUploadRef.current?.clearFiles();
    onCancel();
  };

  const handleUpload = async (name, file) => {
    if (!file) {
      // on clear
      setFieldValue(name, quickLink?.[name] || '');
      return;
    }

    setUploading(true);
    const { url } = await dispatch(uploadDocument(file));

    if (!url) {
      return;
    }

    setFieldValue(name, url);
    setUploading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <TextInput
          label={lang.QUICK_LINK_URL}
          name="url"
          value={values.url}
          error={errors.url}
          onChange={e => setFieldValue('url', e.target.value.trim())}
        />
        <TextInput
          label={lang.QUICK_LINK_TITLE_LABEL}
          name="title"
          value={values.title}
          error={errors.title}
          onChange={e => setFieldValue('title', e.target.value)}
        />

        <div className={styles.uploadIconWrapper}>
          {uploading && (
            <FontAwesomeIcon
              className={styles.iconLoader}
              icon={faSpinner}
              spin={uploading}
              size="2xl"
            />
          )}

          {values.iconUrl && !uploading && (
            <div className={styles.iconWrapper}>
              <label>{lang.QUICK_LINK_PREVIEW}</label>
              <img className={styles.iconUrl} src={values.iconUrl} alt="icon preview" />
            </div>
          )}

          <div className={styles.fileUploadWrapper}>
            <label>{lang.QUICK_LINK_UPDATE_ICON}</label>
            <FileUpload
              ref={fileUploadRef}
              key={lang.QUICK_LINK_UPDATE_ICON}
              name="iconUrl"
              error={errors.iconUrl}
              value={values.iconUrl}
              accept={SUPPORTED_IMG_FORMATS}
              wrapperClass={classNames(styles.uploadWrapper, {
                [styles.errorWrapper]: errors.iconUrl !== undefined,
              })}
              inputStyle="editor"
              onChange={handleUpload}
              allowClear
              previewFileName
            />
            {errors.iconUrl && <span className={styles.error}>{errors.iconUrl}</span>}
          </div>
        </div>
      </div>

      <Divider />

      <div className={styles.actions}>
        <Button type="default" onClick={handleCancel}>
          {lang.CANCEL}
        </Button>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          disabled={!isValid || !dirty || loading}
        >
          {mode === MODES.CREATE ? lang.ADD_LINK : lang.APPLY_CHANGES}
        </Button>
      </div>
    </form>
  );
}
