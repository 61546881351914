import styles from './notification.module.scss';

/**
 * Notification component
 *
 * @param {{
 *  message: string,
 *  renderButtons: () => React.ReactNode,
 * }} params
 * @returns
 */
const Notification = ({ message = '', renderButtons = () => {} }) => {
  return (
    <div className={styles.notification}>
      <h3>{message}</h3>
      {renderButtons()}
    </div>
  );
};

export { Notification };
