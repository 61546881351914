import React from 'react';
import { Button } from '../../../core';
import styles from '../knowledge-upload-file.module.scss';
import { EVENTS, ROUTES } from 'shared/constants';
import { useDispatch } from 'src/store/use-dispatch';
import analyticsService from 'src/helpers/analytics.service';
import { createKnowledgeLink } from 'shared/store/actions/knowledge.items.actions';
import uploadService from 'shared/services/upload.service';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'src/store/use-selector';
import { getUser } from 'shared/store/selectors/user.selector';

/**
 * @type {React.FC<any>}
 */
function DuplicatedFile({ duplicatedItemId, assetId, onCancel, onClose, notificationApi, props }) {
  const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));
  const user = useSelector(getUser);
  const linkPlaceholder = lang.DUPLICATION_SUBTITLE.match(/\{link:.*?\}/)[0];
  const linkText = linkPlaceholder.slice(6, -1); // Extract display text between "{link:" and "}"

  const parts = lang.DUPLICATION_SUBTITLE.split(linkPlaceholder);

  const dispatch = useDispatch();

  const createLinkItem = async () => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DUPLICATION_DETECTED_CREATE_LINK);
    const res = await dispatch(
      createKnowledgeLink({
        categoryId: props?.categoryId,
        linkOwnerIds: [user.id],
        items: [{ id: duplicatedItemId }],
      }),
    );

    onClose();

    if (res?.error) {
      notificationApi.error({
        message: lang.DUPLICATION_LINK_FAILED,
        placement: 'bottomRight',
      });
      return;
    }

    // Reject the duplicated item - notify the server to remove
    await uploadService.rejectDuplicatedItem({ assetId });

    notificationApi.success({
      message: lang.DUPLICATION_LINK_CREATED,
      placement: 'bottomRight',
    });
  };

  return (
    <>
      <div className={styles.loading}>
        <h4 className={styles.duplicationTitle}>{lang.DUPLICATION_TITLE}</h4>
        <h6 className={styles.duplicationSubtitle}>
          {parts[0] || ''}
          <a
            href={ROUTES.KNOWLEDGE_ITEMS.replace(':id', duplicatedItemId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
          {parts[1] || ''}
        </h6>
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} size="medium" onClick={onCancel}>
          {lang.DUPLICATION_DISMISS}
        </Button>
        <Button
          className={styles.button}
          size="medium"
          buttonStyle="primary"
          onClick={createLinkItem}
        >
          {lang.DUPLICATION_LINK}
        </Button>
      </div>
    </>
  );
}

export { DuplicatedFile };
