import { connect } from 'react-redux';
import { SearchComponent } from './search.component';
import { updateSearch } from 'shared/store/actions/search.actions';

const mapStateToProps = ({ lang, search }) => ({
  term: search.term,
  lang: lang.SEARCH_INPUT,
});

export const Search = connect(mapStateToProps, { updateSearch })(SearchComponent);
