import React from 'react';
import { AuthPage } from '../auth-page-wrapper';
import { useHistory } from 'react-router';
import { Lang, OTP_ERROR_MESSAGES, ROUTES } from 'shared/constants';
import styles from './verification.module.scss';
import _ from 'lodash';
import { Loader, OtpInput } from '../../../components/core';
import UserService from 'shared/services/user.service';
import { Button } from 'src/components/design-system';
import { NavLink } from 'react-router-dom-v5-compat';

const VerificationPageComponent = React.memo(({ lang, email }) => {
  let history = useHistory();

  const [error, setError] = React.useState(null);
  const [loading, setLoadingState] = React.useState(false);

  const [attemptsExceed, setAttemptsExceed] = React.useState(false);

  const verifyCode = async ({ code }) => {
    setError('');
    setAttemptsExceed(false);
    setLoadingState(true);

    let { error } = await UserService.verifyCode({ code });

    if (error) {
      if (error === OTP_ERROR_MESSAGES.CODE_DOES_NOT_MATCH) {
        setError(lang.CODE_DOES_NOT_MATCH);
      }

      if (error === OTP_ERROR_MESSAGES.VERIFICATION_ATTEMPTS_LIMIT_EXCEED) {
        setError(lang.VERIFICATION_ATTEMPTS_LIMIT_EXCEED);
        setAttemptsExceed(true);
      }
    }

    setLoadingState(false);
  };

  const resendCode = async () => {
    setError('');
    setAttemptsExceed(false);
    setLoadingState(true);

    let res = await UserService.signin({ email });

    setLoadingState(false);

    if (res?.error) {
      setError(res.error);
    }

    setLoadingState(false);
  };

  React.useEffect(() => {
    if (_.isUndefined(email) || email === '') {
      history.replace('/');
    }
  }, [email, history]);

  const content = lang.ENTER_DIGITS.replace('{email}', email);

  return (
    <AuthPage title={lang.HEADER} content={content} pandaCloseEyes>
      {loading ? <Loader /> : <OtpInput onChange={verifyCode} />}
      <span className={styles.error}>{error}</span>

      {attemptsExceed && (
        <form onSubmit={resendCode}>
          <Button htmlType="submit" className={styles.button}>
            {lang.RESEND_VERIFICATION_CODE}
          </Button>
        </form>
      )}

      <p className={styles.contact}>
        <NavLink to={ROUTES.NOT_ALLOWED} className={styles.link}>
          {lang.CONTACT_US_LINK}
        </NavLink>{' '}
        {lang.CONTACT_US}
      </p>
    </AuthPage>
  );
});

VerificationPageComponent.defaultProps = {
  lang: Lang.OTP_SCREEN,
  email: '',
};

export { VerificationPageComponent };
