import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Lang, USER_TAGS_KEYS } from 'shared/constants';
import { Loader, Button } from '../../../components/core';
import styles from './communication-center.module.scss';
import { Popup } from '../../../components/common';
import { Doctor } from '../../../images';
import { Col, Input, Row, Switch } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { RecipientsSelector } from '../../../components/communication';
import classNames from 'classnames';
import { WorkspaceSelectorInput } from 'src/components/workspaces';
import { ScreenTitle } from 'src/components/design-system';
const { TextArea } = Input;

const TITLE_MAX_LENGTH = 40;
const CONTENT_MAX_LENGTH = 120;
const OPTIONS_DEFAULTS = {
  newsFeed: true,
  sendNotification: true,
  onWorkingHours: false,
};

const CommunicationCenterPageComponent = ({ lang, sendNotification, currentWorkspaceId }) => {
  const [isLoading, setLoadingState] = React.useState(false);
  const [notificationStatus, setNotificationStatus] = React.useState(null);
  const [changeWorkspace, setChangeWorkspace] = React.useState(false);
  const [formSendDate, setFormSendDate] = React.useState(Date.now());

  const MESSAGE_SCHEMA = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().max(TITLE_MAX_LENGTH).required(lang.REQUIRED_TITLE),
        content: Yup.string().max(CONTENT_MAX_LENGTH).required(lang.REQUIRED_CONTENT),
        url: Yup.string().url(lang.INVALID_URL),
        options: Yup.object().shape({
          newsFeed: Yup.boolean(),
          sendNotification: Yup.boolean(),
          onWorkingHours: Yup.boolean(),
        }),
        recipientsTags: Yup.object().shape({
          [USER_TAGS_KEYS.DEPARTMENT]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.ROTATION]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.ROLE]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.LEVEL_OF_TRAINING]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.PROFESSION]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.LOCATION]: Yup.array().of(Yup.string()),
          [USER_TAGS_KEYS.USERS]: Yup.array().of(Yup.string()),
        }),
        workspaceIds: Yup.array().of(Yup.string()),
      }),
    [lang],
  );

  const initialValues = useMemo(
    () => ({
      title: '',
      content: '',
      url: '',
      options: OPTIONS_DEFAULTS,
      recipientsTags: {},
      workspaceIds: [currentWorkspaceId],
    }),
    [currentWorkspaceId],
  );

  const onSubmit = React.useCallback(
    async (data, { setValues }) => {
      setLoadingState(true);

      const res = await sendNotification({ data });

      setNotificationStatus(res);

      // Clean form on success
      if (!res.error) {
        setValues(initialValues);
        setFormSendDate(Date.now());
      }

      setLoadingState(false);
    },
    [sendNotification, initialValues],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    isValid,
  } = useFormik({
    validationSchema: MESSAGE_SCHEMA,
    initialValues,
    onSubmit,
  });

  const dismissNotificationStatus = () => setNotificationStatus(null);

  // Update workspaceIds when currentWorkspaceId changes
  React.useEffect(() => {
    if (currentWorkspaceId) {
      setFieldValue('workspaceIds', [currentWorkspaceId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspaceId]);

  const setOptionsCheckboxState = key => checked => {
    setFieldValue(`options.${key}`, checked);
  };

  const renderConfirmationStatus = () => {
    if (!notificationStatus) return null;

    const message = !notificationStatus.error
      ? lang.NOTIFICATION_SENT_SUCCESS
      : lang.NOTIFICATION_SENT_ERROR;

    return (
      <Popup title={message} isOpen={true}>
        <div className={styles.doctorWrapper}>
          <img src={Doctor} alt="C8.health Doctor" className={styles.doctor} />
        </div>
        <Button className={styles.dismissButton} onClick={dismissNotificationStatus} size="medium">
          {lang.NOTIFICATION_SENT_DISMISS}
        </Button>
      </Popup>
    );
  };

  const renderErrorMessage = () => {
    if (isValid) return null;

    const showTitleError =
      ((touched.content || touched.url) && !touched.title) || (touched.title && errors.title);
    const error = (showTitleError && errors.title) || errors.content || errors.url;

    if (!error) return null;

    return (
      <div className={styles.error}>
        <WarningFilled className={styles.errorIcon} />
        <p>{error}</p>
      </div>
    );
  };

  return (
    <>
      <div className={styles.root} key={formSendDate}>
        <div className={styles.header}>
          <ScreenTitle title={lang.HEADER} />
          <h3>{lang.SUBHEADER}</h3>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <Row gutter={8}>
            <Col span={changeWorkspace ? 12 : 24}>
              <div className={styles.recipientsSelectorHeader}>
                <h4 className={styles.describer}>{lang.RECIPIENTS_DESCRIPTION}</h4>
                {!changeWorkspace && (
                  <span
                    className={styles.workspaceSelector}
                    onClick={() => setChangeWorkspace(true)}
                  >
                    {lang.WORKSPACE_SELECT}
                  </span>
                )}
              </div>
              <RecipientsSelector
                error={errors.recipientsTags}
                onChange={v => setFieldValue('recipientsTags', v)}
                placeholder={lang.RECIPIENTS_PLACEHOLDER}
                wrapperClass={styles.recipients}
              />
            </Col>
            {changeWorkspace && (
              <Col span={12}>
                <h4 className={styles.describer}>{lang.WORKSPACE_HEADER}</h4>
                <WorkspaceSelectorInput
                  value={values.workspaceIds}
                  error={errors.workspaceIds}
                  name="workspaceIds"
                  onChange={setFieldValue}
                  placeholder={lang.WORKSPACE_PLACEHOLDER}
                />
              </Col>
            )}
          </Row>

          <div className={styles.multiInput}>
            <Input
              name="title"
              value={values.title}
              error={errors.title}
              touched={touched.title?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={lang.TITLE_PLACEHOLDER}
              bordered={false}
              size="large"
              maxLength={TITLE_MAX_LENGTH}
              showCount
            />

            <div className={styles.line} />

            <TextArea
              name="content"
              value={values.content}
              error={errors.content}
              touched={touched.content?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={lang.CONTENT_PLACEHOLDER}
              maxLength={CONTENT_MAX_LENGTH}
              showCount
              bordered={false}
              size="large"
            />
          </div>

          <Input
            name="url"
            value={values.url}
            error={errors.url}
            touched={touched.url?.toString()}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={lang.URL_PLACEHOLDER}
            size="large"
            className={styles.inputUrl}
          />
          <h5 className={classNames(styles.describer, styles.urlMessage)}>{lang.URL_MESSAGE}</h5>

          <div className={styles.toggleContainer}>
            <div className={styles.toggleWrapper}>
              <Switch
                className="ant-switch-big"
                defaultChecked={OPTIONS_DEFAULTS.sendNotification}
                checked={values.options.sendNotification}
                onChange={setOptionsCheckboxState('sendNotification')}
              />
              <span className={styles.checkLabel}>{lang.SEND_NOTIFICATION}</span>
            </div>

            <div className={styles.toggleWrapper}>
              <Switch
                className="ant-switch-big"
                defaultChecked={OPTIONS_DEFAULTS.newsFeed}
                checked={values.options.newsFeed}
                onChange={setOptionsCheckboxState('newsFeed')}
              />
              <span className={styles.checkLabel}>{lang.SAVE_TO_NEWS_FEED}</span>
            </div>
            <div className={styles.toggleWrapper}>
              <Switch
                className="ant-switch-big"
                defaultChecked={OPTIONS_DEFAULTS.onWorkingHours}
                checked={values.options.onWorkingHours}
                onChange={setOptionsCheckboxState('onWorkingHours')}
              />
              <span className={styles.checkLabel}>{lang.ON_WORKING_HOURS}</span>
            </div>
          </div>

          {!isLoading && (
            <>
              {renderErrorMessage()}
              <Button
                className={styles.button}
                size="medium"
                type="submit"
                buttonStyle="primary"
                disabled={!isValid}
              >
                {lang.SEND}
              </Button>
            </>
          )}
          {isLoading && <Loader />}

          {renderConfirmationStatus()}
        </form>
      </div>
    </>
  );
};

CommunicationCenterPageComponent.defaultProps = {
  lang: Lang.COMMUNICATION_CENTER,
  sendNotification: () => {},
};

export { CommunicationCenterPageComponent };
