import classNames from 'classnames';
import styles from './image-overlay.module.scss';

export function ImageOverlay({
  img,
  overlay,
  className = '',
  overlayClassName = '',
  imgClassName = '',
}) {
  return (
    <div className={classNames(styles.container, className)}>
      {/* Image container */}
      <div className={classNames(styles.imageContainer, imgClassName)}>{img}</div>

      {/* Overlay container */}
      <div className={classNames(styles.overlay, overlayClassName)}>{overlay}</div>
    </div>
  );
}
