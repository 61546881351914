import React from 'react';
import { Lang } from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button } from 'src/components/design-system';
import styles from './admission-generate-report.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Select, TextInput } from 'src/components/design-system';
import { formatFullName } from 'shared/utils';
import { Checkbox } from 'antd';

const REPORT_SCHEMA = Yup.object().shape({
  signature: Yup.object().shape({
    name: Yup.string().max(200, 'Too Long!').required('Name is required'),
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
  }),
  recipients: Yup.array()
    .of(Yup.string().email('Email must be a valid email. '))
    .when('sendMeCopy', {
      is: false,
      then: schema => schema.min(1, 'At least one email needs to be chosen').required(),
      otherwise: schema => schema.notRequired(),
    }),
  sendMeCopy: Yup.boolean(),
});

const AdmissionGenerateReportComponent = React.memo(function AdmissionGenerateReportComponent({
  lang,
  generateChecklistReport,
  onFinish,
  user,
  users,
}) {
  const [isLoading, setLoadingState] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState('');

  const createReport = React.useCallback(
    async values => {
      setLoadingState(true);

      const { recipients, sendMeCopy, signature } = values;
      if (sendMeCopy) {
        recipients.push(signature.email);
      }

      const res = await generateChecklistReport({
        signature,
        recipients,
        sendByEmail: true,
      });

      if (res.url) {
        setReportUrl(res.url);
      }
      setLoadingState(false);
    },
    [generateChecklistReport],
  );

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const close = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const recipientsOptions = React.useMemo(() => {
    return users
      .filter(u => u.id !== user.id)
      .map(u => ({
        value: u.email,
        label: formatFullName(u, true),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [users, user]);

  const { handleChange, handleSubmit, setFieldValue, isValidating, values, errors, isValid } =
    useFormik({
      validationSchema: REPORT_SCHEMA,
      initialValues: {
        signature: {
          name: formatFullName(user),
          email: user.email,
        },
        sendMeCopy: false,
        recipients: [],
      },
      onSubmit: createReport,
    });

  const handleChangeRecipients = value => {
    setFieldValue('recipients', [...new Set(value).values()]);
  };

  const renderContent = () => {
    if (!reportUrl) {
      return (
        <form onSubmit={handleSubmit}>
          <TextInput
            label={lang.SIGNATURE_NAME}
            name="signature.name"
            value={values.signature.name}
            error={errors.signature?.name}
            onChange={handleChange}
            placeholder={lang.SIGNATURE_NAME_PLACEHOLDER}
          />

          <TextInput
            label={lang.SIGNATURE_EMAIL}
            name="signature.email"
            value={values.signature.email}
            error={errors.signature?.email}
            onChange={handleChange}
            placeholder={lang.SIGNATURE_EMAIL_PLACEHOLDER}
          />

          <Checkbox
            className={styles.checkbox}
            checked={values.sendMeCopy}
            onChange={handleChange('sendMeCopy')}
            title={lang.SEND_ME_COPY_PLACEHOLDER}
          >
            <label className={styles.label}>{lang.SEND_ME_COPY}</label>
          </Checkbox>

          <Select
            label={lang.RECIPIENTS}
            mode="tags"
            value={values.recipients}
            name="recipients"
            error={errors.recipients}
            onChange={(name, value) => handleChangeRecipients(value)}
            size="large"
            options={recipientsOptions}
          />

          <div className={styles.buttons}>
            <Button type="default" disabled={isLoading} onClick={dismiss}>
              {lang.CANCEL}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!isValid || isValidating}
              loading={isLoading}
            >
              {lang.SEND}
            </Button>
          </div>
        </form>
      );
    }

    return (
      <div className={styles.success}>
        <h3>{lang.SEND_TO}</h3>
        <h4>{[...values.recipients].join(', ')}</h4>
        <p>
          <a target="_blank" href={reportUrl} rel="noreferrer">
            {lang.CLICK_HERE}
          </a>
          {` ${lang.VIEW_LINK}`}
        </p>

        <Button className={styles.closeBtn} onClick={close}>
          {lang.OK}
        </Button>
      </div>
    );
  };

  return (
    <Popup key={lang.TITLE} title={reportUrl ? lang.TITLE_AFTER_SIGN : lang.TITLE} isOpen>
      {renderContent()}
    </Popup>
  );
});

AdmissionGenerateReportComponent.defaultProps = {
  lang: Lang.ADMISSION_GENERATE_REPORT,
  generateChecklistReport: () => {},
  onFinish: () => {},
};

export { AdmissionGenerateReportComponent };
