import React from 'react';
import styles from './titles.module.scss';

const SectionTitle = React.memo(({ title = '' }) => (
  <div className={styles.sectionTitleContainer}>
    <h2>{title}</h2>
  </div>
));

export { SectionTitle };
